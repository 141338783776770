import { WorkshopSchema } from "Interfaces/reducerTypes/IeltsPlusSchema";
import { BatchSchema } from "Interfaces/reducerTypes/BatchDetailsSchema";
import { priceBundleName } from "DataMapper/Common/WorkshopData";
import {
  isPremiumBatch,
  isPremiumBundleCheck,
  isIeltsPlusPart2BatchBundle,
  diffWeeks,
  isIeltsPlusPart2Batch,
} from "utils/ielts_batch_utils";
import { ietlsPlusFilterData } from "DataMapper/IeltsSection/valueProps";

export const filterLiveClass = (data: WorkshopSchema) =>
  new Date(data.meetingStartTime) <= new Date() &&
  new Date(data.meetingEndTime) > new Date();

export const repackUpcomingClass = (
  data: any,
  cb?: Function | null,
  filterPriorityList?: Array<string>
) => {
  if (!cb) {
    if (!filterPriorityList) {
      return [].concat.apply([], Object.values(data));
    } else {
      return [].concat.apply(
        [],
        filterPriorityList.map((workshop: string) => data[workshop])
      );
    }
  }
  if (filterPriorityList)
    return [].concat
      .apply(
        [],
        filterPriorityList.map((workshop: string) => data[workshop])
      )
      .filter((data: WorkshopSchema) => (cb ? cb(data) : true));
  else
    return [].concat
      .apply([], Object.values(data))
      .filter((data: WorkshopSchema) => (cb ? cb(data) : true));
};

export const ieltsBatchSorting = (batchData: Array<BatchSchema>) => {
  const foundation = batchData.filter(
    (data) => data.pricingBundleName === priceBundleName.IELTS_FOUNDATION
  );
  const plus = batchData.filter(
    (data) => data.pricingBundleName === priceBundleName.IELTS_PLUS
  );
  const premium = batchData.filter((data) => isPremiumBatch(data));
  const ieltsPlusPart2Batches = batchData.filter((item) =>
    isIeltsPlusPart2BatchBundle(item.pricingBundleName)
  );
  return [...premium, ...plus, ...foundation, ...ieltsPlusPart2Batches];
};

export const reduceOnlineWorkshopEnrollment = (
  result: {
    plusEnrollment: number;
    foundationEnrolment: number;
    premiumEnrolment: number;
  },
  data: BatchSchema
) => {
  if (data.pricingBundleName === priceBundleName.IELTS_FOUNDATION)
    return { ...result, foundationEnrolment: result.foundationEnrolment + 1 };
  else if (data.pricingBundleName === priceBundleName.IELTS_PLUS)
    return { ...result, plusEnrollment: result.plusEnrollment + 1 };
  else return { ...result, premiumEnrolment: result.plusEnrollment + 1 };
};

export const getPriceBundleName = (
  priceBundle: priceBundleName | string,
  removeIelts: boolean | undefined = false
) => {
  if (priceBundle == priceBundleName.IELTS_FOUNDATION) {
    if (removeIelts) return "Foundation";
    return "IELTS Foundation";
  }
  if (isPremiumBundleCheck(priceBundle)) {
    if (removeIelts) return "Premium";
    return "IELTS Premium";
  }
  if (removeIelts) return "Plus";
  return "IELTS Plus";
};

export const excludeTimeFromDate = (date: Date): string => {
  let newDate = new Date();
  newDate.setDate(date.getDate());
  newDate.setMonth(date.getMonth());
  newDate.setFullYear(date.getFullYear());
  return newDate.toString();
};

export const getDateBundler = (
  classData: Array<WorkshopSchema>,
  maxDateList: number = 3
) => {
  let dateBundler: any = {};
  classData.forEach((value) => {
    let classDate = excludeTimeFromDate(new Date(value.meetingStartTime));
    if (dateBundler[classDate]) {
      dateBundler[classDate] = [...dateBundler[classDate], value];
    } else {
      dateBundler[classDate] = [value];
    }
  });
  return dateBundler;
};

export const classIsLive = (startTime: string, endTime: string) => {
  return new Date() >= new Date(startTime) && new Date() <= new Date(endTime);
};

export type singleFilterType = { id: number; text: string };
export type filteredBatchesMapType = Record<string, WorkshopSchema[]>;
export const getFilteredBatchesMap = (
  upcomingIeltsBatchesData: WorkshopSchema[],
  includeIeltsPlusPart2Batches: boolean = false
): {
  filteredBatchesMap: filteredBatchesMapType;
  typesToBeShown: singleFilterType[];
} => {
  let BatchesToBeShown: WorkshopSchema[] = [];

  if (includeIeltsPlusPart2Batches === true) {
    BatchesToBeShown = upcomingIeltsBatchesData.filter(
      (item) => isPremiumBatch(item) || isIeltsPlusPart2Batch(item)
    );
  } else {
    BatchesToBeShown = upcomingIeltsBatchesData.filter((item) =>
      isPremiumBatch(item)
    );
  }
  const weeksFilters: filteredBatchesMapType = BatchesToBeShown.reduce(
    (acc: filteredBatchesMapType, curr) => {
      const weeksDurationNumber: number = diffWeeks(
        curr.startTime,
        curr.endTime
      );
      if (Array.isArray(acc[weeksDurationNumber])) {
        acc[weeksDurationNumber].push(curr);
      } else {
        acc[weeksDurationNumber] = [curr];
      }
      return acc;
    },
    {}
  );
  const typesToBeShown = Object.keys(weeksFilters).map((item) => ({
    id: parseInt(item),
    text: `${item} ${parseInt(item) === 1 ? "week" : "weeks"}`,
  }));
  typesToBeShown.unshift({ id: ietlsPlusFilterData.ALL, text: "All" });
  weeksFilters[ietlsPlusFilterData.ALL] = BatchesToBeShown;
  if (includeIeltsPlusPart2Batches === true) {
    const ieltsPlusPart2Batches = BatchesToBeShown.filter((item) =>
      isIeltsPlusPart2Batch(item)
    );

    if (ieltsPlusPart2Batches.length > 0) {
      weeksFilters[ietlsPlusFilterData.IELTS_PLUS_PART_2] =
        ieltsPlusPart2Batches;
      typesToBeShown.push({
        id: ietlsPlusFilterData.IELTS_PLUS_PART_2,
        text: "IELTS Plus Part 2",
      });
    }
  }

  return {
    filteredBatchesMap: weeksFilters,
    typesToBeShown,
  };
};
