import { getCookie } from 'utils/cookie-utiils';
import { mobileCheck } from 'utils/browserUtils';
import { FlowType } from 'hooks/useLogin';

export const getFlowType = (url: string) => {
  let queryStrings = url.split('?')[1];
  if (queryStrings) {
    let flowType = queryStrings.split('&')[0];
    if (flowType) {
      return flowType.split('=')[1];
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const checkIeltsRoute = (): boolean => {
  if (
    typeof window != 'undefined' &&
    window.location.pathname.match('/ielts-preparation-online')
  ) {
    return true;
  } else {
    return false;
  }
};

export function youtubeParser(url: string) {
  try {
    let regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = url.match(regExp);
    return match && match[7].length == 11 ? match[7] : '';
  } catch (e) {
    return '';
  }
}

export const getIeltsMockTestUrl = (reqUrl: string) => {
  const mockTestBasePath = process.env.MOCK_TEST_URL;
  const token = getCookie(null, 'token');
  let userId = getCookie(null, 'user_id');
  let source = mobileCheck() ? 'Mweb' : 'WEB';
  return `${mockTestBasePath}/redirectPageV2?token=${token}&userId=${userId}&requrl=${reqUrl}&source=${source}`;
};

export const loginFlowType = () => {
  if (checkIeltsRoute()) return FlowType.IELTS;
  else return FlowType.COUNSELLING;
};

export const redirectOutFromIframe = (url: string) => {
  try {
    if (window && window.top) {
      window.top.location.href = url;
    }
  } catch (err: any) {
    console.log(err);
  }
};
