import { nanoid } from '@reduxjs/toolkit';

export const getInfoMessageApi = ({
  requestId,
  path,
  message,
  statusCode,
  userId,
  apiData,
  apiHeaders,
}: {
  requestId: string;
  path: string;
  message: string;
  userId?: string;
  statusCode?: number;
  apiData?: any;
  apiHeaders?: any;
}): string => {
  return `requestId = ${requestId} | api path = ${path} |  message = ${message} | userId = ${
    userId ?? 'NA'
  } | statusCode = ${statusCode ?? 'NA'} | apiData = ${
    JSON.stringify(apiData) ?? 'NA'
  } | apiHeaders = ${JSON.stringify(getRequiredHeaders(apiHeaders)) ?? 'NA'}`;
};
export const getRequiredHeaders = (
  headers: Record<string, any>,
): Record<string, any> => {
  if (headers) {
    return {
      'X-API-VERSION': headers['X-API-VERSION'],
      'X-PLATFORM': headers['X-PLATFORM'],
      Custom_IP: headers['Custom_IP'],
    };
  }
  return {};
};

export function generateRequestId() {
  return nanoid();
}
