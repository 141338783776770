import {
  MOCK_TEST_TYPE,
  mockTestCategory,
} from 'DataMapper/IeltsProfile/MockTest';
import { getIeltsMockTestUrl } from 'utils/url_utils';

export const navRoutes = {
  HOMEPAGE: '/',
  HOMEPAGECOPY: '/home',
  FIND_UNIVERSITY: '/college-list',
  USA_MENTORS: '/connect-with-mentors',
  USA_MENTOR_VIDEOS: '/mentor-videos',
  USA_MENTOR_VIDEOS_VIDEO: '/mentor-videos/[video]',
  COUNTRY_COLLEGE: '/[country]/[college]',
  COUNTRY_COLLEGE_ALLCOURSES: '/[country]/[college]/all-courses',
  COUNTRY_COLLEGE_COURSE: '/[country]/[college]/[course]',
  COMMUNITY: '/community',
  PROFILE_COMMUNITY: '/profile/community',
  MENTORS: '/mentors',
  COUNSELLORS: '/counsellors',
  SCHOLARSHIP: '/scholarship-finder',
  SCHOALRSHIP_APPLICATION: '/scholarship',
  SCHOALRSHIP_FAQ: '/scholarship/faqs',
  ABOUT_US: '/about-us',
  IELTS_PAGE: '/ielts-free-online-coaching',
  NEW_MASTERCLASS_LANDING_PAGE: '/ielts-masterclass',
  IELTS_CRASH_COURSE: '/ielts-preparation-online/crash_course',
  IELTS_FREE_CRASH_COURSE: '/ielts-preparation-online/free_crash_course',
  IELTS_AI_EVALUTION: '/ielts-preparation-online/smartTests',
  IELTS_ALL_BATCHES: '/ielts-preparation-online/crash_course/all_batches',
  IELTS_BATCHES_PAGE: '/ielts-preparation-online/crash_course/ielts_batches',
  IELTS_FOUNDATION_BATCH:
    '/ielts-preparation-online/crash_course/all_batches?batch=foundation',
  IELTS_PLUS_BATCH:
    '/ielts-preparation-online/crash_course/all_batches?batch=plus',
  IELTS_PREMIUM_BATCH:
    '/ielts-preparation-online/crash_course/all_batches?batch=premium',
  IELTS_JOIN_WAITLIST: '/ielts-preparation-online/crash_course/join_waitlist',
  IELTS_BATCH_DETAILS:
    '/ielts-preparation-online/crash_course/batches/[batchId]',
  PRIVACY_PAGE: '/privacy',
  PROFILE: '/profile',
  PROFILE_MASTERCLASS: '/profile?flowType=ielts&section=ieltsMasterclass',
  EVENTS: '/events',
  EVENTS_DETAILS: '/events/[eventId]',
  // Login Based URLs
  LOGIN_IELTS: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile?flowType=ielts&section=ieltsPlus',
  )}`,
  LOGIN_IELTS_MASTERCLASS: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile?flowType=ielts&section=ieltsMasterclass',
  )}`,
  LOGIN_IELTS_PRO: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile?flowType=ielts',
  )}`,
  LOGIN_COUNSELLING: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile?flowType=counselling',
  )}`,
  LOGIN_PROFILE: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile/user_acquried',
  )}`,

  POSTLOGIN_COUNSELLING: `/profile`,
  POSTLOGIN_IETLS_MASTERCLASS: `/profile/ielts_plus/overview?flowType=ielts`,
  POSTLOGIN_IELTS_PRO: `/profile/ielts_plus/overview?flowType=ielts`,
  REDIRECTION_IELTS_COMMUNITY: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile/join_ielts_community?redirect=/ielts-free-online-coaching&community=https://t.me/LeapScholar_IELTS_prep',
  )}`,
  USER_ACQUIRED: '/profile/counselling/user_acquried',
  LEAD_CAPTURE: '/profile/counselling/lead_capture',
  CALL_SCHEDULED: '/profile/counselling/call_scheduled',
  CALL_DONE: '/profile/counselling/call_done',
  PAYMENT_DONE: '/profile/counselling/payment_done',
  REDIRECTION_COMMUNITY: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile/join_ielts_community?redirect=/&community=https://t.me/joinchat/TvPaO3upIMaYMHJd',
  )}`,

  UPDATE_PROFILE: '/profile/update-profile',
  DOCUMENTS_UPLOAD: '/profile/documents_upload',
  VISA_DOCUMENTS_UPLOAD: '/profile/visa-documents-upload',
  APPLY_FOR_SCHOLARSHIP: '/profile/apply-for-scholarship',
  IELTS_REGISTRATION_FORM: '/profile/ielts_pro_registration',
  MASTERCLASS_REGISTRATION_FORM: '/profile/masterclass_registration',
  SINGLE_DOCUMENT_UPLOAD_FORM: '/profile/single_document_upload',
  IELTS_STUDENT_DASHBOARD: '/test-prep/dashboard',
  IELTS_NOTICE_BOARD: '/profile/ielts_plus/notice_board',
  IELTS_CLASS_RECORDINGS: '/profile/ielts_plus/class_recordings',
  IELTS_STUDY_MATERIAL: '/profile/ielts_plus/study_material',
  IELTS_SPEAKING_CLUB: '/profile/ielts_plus/speaking_club',
  IELTS_CLASS_LIST: '/profile/ielts_plus/class_list',
  IELTS_MOCK_TEST: '/profile/ielts_plus/mock_test',
  IELTS_CERTIFICATES: '/profile/ielts_plus/certificate',
  PROFILE_SCHOLARSHIP: '/profile/scholarship',
  REGISTER_FOR_FINANCIAL_COUNSELLING:
    '/profile/register_for_financial_counselling',
  REGISTER_FOR_IELTS: '/profile/register_for_ielts',
  IELTS_ESSAY_EVALUATION: '/profile/ielts_plus/essay_evaluation',
  CAREERS_PAGE: 'https://leapfinance.freshteam.com/jobs',
  IELTS_TO_COUNSELLING_NUDGE: '/counselling-nudge',
  LOGIN_IELTS_VIA_NAV: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile?flowType=ielts&type=navbar',
  )}`,
  LOGIN_COUNSELLING_VIA_NAV: `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    '/profile?flowType=counselling&type=navbar',
  )}`,
  MEETING_FINDER: '/meeting-finder',
  CRACK_IELTS_FAST_TRACK:
    'https://crackielts.leapscholar.com/ls-ielts-fasttrack',
  FAST_TRACK_LANDING_PAGE: '/ielts-preparation-online/fast_track',
  FAST_TRACK_VIDEO: '/profile/fast_track_video',
  FAST_TRACK_FORM: '/profile/fast_track_registration',
  FAST_TRACK: '/profile/ielts_plus/fast_track',
  PLUS_UPCOMING_CLASS: '/profile/ielts_plus/plus_class',
  PLUS_COMPLETE_CLASS: '/profile/ielts_plus/plus_complete',

  //new paths for counselling flow - start
  HOME: '/profile/counselling',
  COUNSELLING_OVERVIEW: '/profile/counselling/overview',
  COUNSELLING_MY_UNIVERSITIES: '/profile/counselling/my_universities',
  COUNSELLING_DOCUMENT_DETAILS: '/profile/counselling/document_details',
  COUNSELLING_NOTICE_BOARD: '/profile/counselling/notice_board',
  COUNSELLING_UPCOMING_APPOINTMENTS:
    '/profile/counselling/upcoming_appointments',
  COUNSELLING_FINANCIAL_ASSISTANCE: '/profile/counselling/financial_assistance',
  COUNSELLING_VISA_ASSISTANCE: '/profile/counselling/visa_assistance',
  //new path for counselling floe - end
  TELEGRAM_FAST_TRACK: 'https://t.me/joinchat/P6eGFG2AHWdmNWFl',
  SC_PROFILE: '/profile/counselling/sc_profile',
  OFFER_DETAILS: '/profile/counselling/offer',
  TERM_AND_CONDITION: '/terms',
  REFUND_POLICY: '/refund-policy',
  ONBOARDING_PAGE: '/onboarding',
  MASTERCLASS_MOCK_TEST: `/profile/ielts_plus/mock_test?${MOCK_TEST_TYPE}=${mockTestCategory.masterclass_mock_test}`,
  MOCK_TEST_PRODUCT: 'https://pages.razorpay.com/ielts-mock-tests',
  CHAT: '/profile/chat',
  IELTS_REWARDS: '/profile/ielts/rewards',
  MASTERCLASS_LIST: '/profile/ielts_plus/masterclass_list',
  PROFILE_NAV: '/profile?type=navbar',
  REPLAYS_VIDEO_LIST: '/profile/replays',
  EXTRA_CLASS_LIST: '/profile/ielts_plus/extraclass_list',
  IELTS_QUIZ: '/profile/ielts_quiz',
  IELTS_COMPLETE_QUIZ: '/profile/ielts_quiz?section=complete',
  BOOK_IELTS_TEST: '/profile/counselling/book_ielts_exam',
  MASTERCLASS_TYPE: '/ielts-preparation-online/masterclass',
  BEGINNER_MASTERCLASS: '/ielts-preparation-online/masterclass?type=beginner',
  CLASSIC_MASTERCLASS: '/ielts-preparation-online/masterclass?type=classic',
  ALL_PLUS_EXCLUSIVE:
    '/ielts-preparation-online/masterclass?type=PLUS_EXCLUSIVE',
  COUNSELLING_APP:
    'https://play.google.com/store/apps/details?id=com.leapscholar.app',
  IELTS_APP: 'https://play.google.com/store/apps/details?id=com.knudge.ielts',
  JOIN_CLASS: '/profile/join/',
  IELTS_TEST: '/profile/ielts_test',

  // TOFU Landing Pages
  USA_HOMEPAGE: '/study-in-usa',
  CANADA_HOMEPAGE: '/study-in-canada',
  AUSTRALIA_HOMEPAGE: '/study-in-australia',
  UK_HOMEPAGE: '/study-in-uk',
  IRELAND_HOMEPAGE: '/study-in-ireland',
  GERMANY_HOMEPAGE: '/germany',

  //Spot Counselling
  SPOT_COUNSELLING: '/spot-counselling',
  SPOT_COUNSELLING_CALENDER: '/spot-counselling/calender',
  SPOT_COUNSELLING_PAYMENT: '/spot-counselling/payment',
  SCHEDULE_MEETING: '/spot-counselling/schedule-meeting',
  SPOT_COUNSELLING_ONBOARD: '/spot-counselling/onboard',
  MOCK_TEST_LANDING_PAGE: '/profile/ielts_plus/ielts_mock_test',
  IELTS_BATCHCLASS_SCHEDULE_PAGE: '/profile/ielts_plus/ielts_batch_schedule',
  PRACTISE_TAB: '/android_app/module',
  ANDROID_MOCK_TEST:
    '/profile/ielts_plus/app_mock?mock_test_type=event_mock_test',
  ANDROID_MOCK_TEST2: 'https://leapielts.app.link/open/ielts_mock_test',
  ANDROID_VOICE_ROOMS: 'https://leapielts.app.link/open/voiceroom',
  ANDROID_JOIN_BATCH_CLASS: 'https://leapielts.app.link/open/join_class',
  IELTS_MOCK_TEST_PAGE: getIeltsMockTestUrl('exam/IELTS'),
  AUTH_PATH: '/api/avataran-route/avataran_auth?path=',
  MASTER_PROGRAM: 'https://advantage.leapscholar.com/',
  UNDERGRADUATE_PROGRAM: 'https://advantage.leapscholar.com/for-undergrad',
  LOGOUT: '/api/avataran-route/avataran_logout',

  //webflow pages
  IELTS_ELITE_WEBFLOW_PAGE:
    'https://ielts.leapscholar.com/leap-ielts-demo?utm_source=website&utm_medium=organic&utm_campaign=organic',
  IELTS_COURSE_LIST_PAGE: '/ielts-preparation-online/courses',
  ANDROID_DASHBOARD: 'https://leapielts.app.link',
  ANDROID_MASTERCLASS: 'https://leapielts.app.link/open/masterclass',
  ANDROID_MOCKTEST: 'https://leapielts.app.link/open/tabmocktests',
  IELTS_TEST_PREP_MOCK_TEST: '/profile/ielts/mocktest',
  BOOK_IELTS_DEMO_FLOW:
    '/ielts-preparation-online/ielts-elite-demo/user-details-form',
  IELTS_ELITE_SLOTS_SCREEN: '/ielts-preparation-online/ielts-elite-demo',
  IELTS_LIY_VALUE_PROPS:
    '/ielts-preparation-online/ielts-elite-demo/liy-value-props',
  IELTS_SELF_PREP: '/profile/ielts/self-prep',
  GENERIC_PAYMENT: '/product',

  // BLOGS Page
  BLOGS: "/blog/"
};

export const handleDynamicQueryStrings = (
  dynamicParam: string,
  bundleName: string = 'IELTS_PLUS',
): string => {
  return `/api/avataran-route/avataran_login?callbackUrl=${encodeURIComponent(
    `/profile?flowType=ielts&section=ieltsPlus&${dynamicParam}&bundle=${bundleName}`,
  )}`;
};

export const getProfileIeltsPlus = (
  batchId: number,
  bundleName: string = 'IELTS_PLUS',
): string => {
  return `/profile?flowType=ielts&section=ieltsPlus&batch=${batchId}&bundle=${bundleName}`;
};

//****** all usa page routes - start *****//
const {
  USA_HOMEPAGE,
  FIND_UNIVERSITY,
  USA_MENTORS,
  USA_MENTOR_VIDEOS,
  USA_MENTOR_VIDEOS_VIDEO,
  COUNTRY_COLLEGE,
  COUNTRY_COLLEGE_ALLCOURSES,
  COUNTRY_COLLEGE_COURSE,
} = navRoutes;
export const USAPageRoutes = [
  USA_HOMEPAGE,
  FIND_UNIVERSITY,
  USA_MENTORS,
  USA_MENTOR_VIDEOS,
  USA_MENTOR_VIDEOS_VIDEO,
  COUNTRY_COLLEGE,
  COUNTRY_COLLEGE_ALLCOURSES,
  COUNTRY_COLLEGE_COURSE,
];
//****** all usa page routes - end *****//
