import { captureException } from '@sentry/nextjs';
import api from 'services/api';

const fetchIeltsPlusData = async () => {
  try {
    const res = await api.get(
      '/api/avataran-route/avataran_scholarRoute?path=student/ielts/fee/v2',
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err: any) {
    console.log(err);
    throw new Error('Something went wrong. Please try after sometime');
  }
};

const enrollStudentToIeltsPlus = async (batchId: number) => {
  try {
    const res = await api.post(
      `/api/avataran-route/avataran_scholarRoute?path=student/workshop/1/${batchId}/enroll`,
    );
    if (res.data.success) {
      return true;
    } else {
      throw new Error(res.data.message);
    }
  } catch (err: any) {
    throw new Error('Something went wrong. Please try after sometime');
  }
};

const fetchObjects = async (batchId: string) => {
  try {
    const res = await api.get(
      `/api/avataran-route/avataran_scholarRoute?path=workshop/objects/${batchId}`,
    );
    return res;
  } catch (err: any) {
    throw new Error('Something went wrong. Please try after sometime');
  }
};

const fetchEnrolledBatches = async () => {
  try {
    const headers = {
      'X-API-VERSION': '3',
    };
    const res = await api.get(
      `/api/avataran-route/avataran_scholarRoute?path=student/enrolled/batches`,
      { headers },
    );
    return res.data;
  } catch (err: any) {
    console.log(err);
  }
};

const fetchRewardList = async (batchId: number) => {
  try {
    const res = await api.get(
      `/api/avataran-route/avataran_scholarRoute?path=reward/list/${batchId}`,
    );
    return res.data;
  } catch (err: any) {
    throw new Error('Something went wrong. Please try after sometime');
  }
};
const fetchStudentDetails = () =>
  api.get(`/api/avataran-route/avataran_scholarRoute?path=student/basic`);

const fetchQeFormDataApi = async () =>
  await api.get(
    `/api/avataran-route/avataran_scholarRoute?path=ielts-elite/details`,
  );
const postQeFormDataApiBeforLogin = async (formData: any) => {
  try {
    await api.post(
      `/api/avataran-route/avataran_scholarRoute?path=user/pre-user-details/unverified`,
      formData,
    );
  } catch (error: any) {
    captureException(
      `Mena postQeFormDataApiBeforLogin api failed: ${error?.message}`,
    );
  }
};
export const ieltsPlusApi = {
  fetchIeltsPlusData,
  enrollStudentToIeltsPlus,
  fetchObjects,
  fetchEnrolledBatches,
  fetchRewardList,
  fetchStudentDetails,
  fetchQeFormDataApi,
  postQeFormDataApiBeforLogin,
};
