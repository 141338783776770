import { TeacherSchema } from 'Interfaces/reducerTypes/TeacherSchema';

export const facultyDataParser = (facultyData: Array<any>) => {
  try {
    const visibleOnHomePage = facultyData.filter(
      (data: TeacherSchema) => data.visibleOnHomePage,
    );
    return visibleOnHomePage.reduce((a: any, c: any) => {
      const newObj = JSON.parse(JSON.stringify(c));
      newObj.headerText = `${newObj.yearsOfExperience}+ years of experience`;
      newObj.bodyText = newObj.description;
      newObj.footerText = newObj.shortDescription;
      newObj.image = newObj.mobileImage;
      a.push(newObj);
      return a;
    }, []);
  } catch (e) {
    return [];
  }
};
