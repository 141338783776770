import {
  LoginOptionDto,
  LOGIN_EVENTS,
  LoginCallback,
  OnSubscribeCallback,
} from '@leapfinance/auth-kit';
import {
  segmentEvents,
  trackClick,
  trackIeltsEvent,
  trackSignup,
} from 'scripts/segment';
import { pageName } from 'utils/events-utils';
import { fireTagEvent, logEvent } from 'ga';
import { getTrackingId } from 'hooks/useLogin';
import { eventCategory } from 'DataMapper/EventTracker/EventData';
import { getUtmData } from 'utils/utm_utils';
import exp from 'constants';

type LoginSuccessEvent = (
  option?: LoginOptionDto,
  eventParam?: { label: string; category: string } | null,
) => void;

export const generateOtpSubscriber: OnSubscribeCallback = (option) => {
  trackClick(pageName.Login_Modal, {
    widgetName: 'Login Modal',
    widgetFormat: 'Modal',
    contentName: 'Get OTP',
    contentFormat: 'Button',
    phone: option?.phone,
  });
};

export const submitOtpSubscriber: OnSubscribeCallback = (option) => {
  trackClick(pageName.Login_Modal, {
    widgetName: 'Login Modal',
    widgetFormat: 'Modal',
    contentName: 'Submit OTP',
    contentFormat: 'Button',
    phone: option?.phone,
  });
};

export const resendOtpSubscriber: OnSubscribeCallback = (option) => {
  trackClick(pageName.Login_Modal, {
    widgetName: 'Login Modal',
    widgetFormat: 'Modal',
    contentName: 'Resend OTP',
    contentFormat: 'Button',
    phone: option?.phone,
  });
};

export const generateOtpSuccessSubscriber: LoginSuccessEvent = (
  option,
  eventParam,
) => {
  if (eventParam) logEvent(eventParam.category, 'Get OTP', eventParam.label);
};

export const loginFailureSubscriber = (option?: LoginOptionDto) => {
  trackIeltsEvent(segmentEvents.OTP_ERROR, {
    phoneNumber: option?.phone,
    errorCode: option?.errorLogs?.errorCode,
    errorMessage: option?.errorLogs?.errorMessage,
    currentPage: window.location.pathname,
    refererUrl: document?.referrer,
    loginStep: option?.errorLogs?.errorType,
  });
};
export const resendOtpSuccessSubscriber: LoginSuccessEvent = (
  option,
  eventParam,
) => {
  if (eventParam) logEvent(eventParam.category, 'Resend OTP', eventParam.label);
};

export const loginWithTruecallerSubscriber: OnSubscribeCallback = (option) => {
  trackClick(pageName.Login_Modal, {
    widgetName: 'Login Modal',
    widgetFormat: 'Modal',
    contentName: 'Login with Truecaller',
    contentFormat: 'Button',
  });
};

const webFlowType = {
  WEB_FLOW_MASTERCLASS: 'webflow_masterclass',
  WEB_FLOW_FASTTRACK: 'webflow_fasttrack',
};

export const getEventParams = (callbackUrl: string) => {
  if (callbackUrl) {
    const trackingId = getTrackingId(callbackUrl);
    if (trackingId === webFlowType.WEB_FLOW_MASTERCLASS) {
      return {
        category: trackingId,
        label: 'IELTS - MasterClass Landing Page',
      };
    } else if (trackingId === webFlowType.WEB_FLOW_FASTTRACK) {
      return {
        category: trackingId,
        label: 'IELTS Fast Track',
      };
    } else return null;
  }
  return null;
};

export const postLoginEvent = async (
  registerUserDetails: any,
  path: string,
  flowType: string,
) => {
  if (!registerUserDetails) return;
  const utmDetails = getUtmData(null);
  const trackPayload = {
    pageName: path || 'NA',
    authType: registerUserDetails?.data?.newUser ? 'Sign Up' : 'Sign In',
    utmCampaign: utmDetails?.utmCampaign || '',
    utmSource: utmDetails?.utmSource || '',
    utmTerm: utmDetails?.utmTerm || '',
    adId: utmDetails?.adId || '',
    flowType: flowType,
    userId: registerUserDetails?.data?.userId || '',
  };
  await trackSignup('Log In Successful', trackPayload);
  logEvent('login success', 'login success', '');
  if (registerUserDetails?.data?.newUser) {
    await fireTagEvent('complete registration');
    logEvent(eventCategory.NEW_IELTS_LEAD, path, '');
  }
};
