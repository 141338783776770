import { leapLogin, LOGIN_EVENTS, LoginContent } from '@leapfinance/auth-kit';
import { useSelector } from 'react-redux';
import { loginApis } from 'components/Login/LoginApis';
import { useRouter } from 'next/router';
import { deleteCookie, saveCookie } from 'utils/cookie-utiils';
import { useEffect, useMemo, useState } from 'react';
import {
  generateOtpSubscriber,
  generateOtpSuccessSubscriber,
  getEventParams,
  loginFailureSubscriber,
  loginWithTruecallerSubscriber,
  resendOtpSubscriber,
  resendOtpSuccessSubscriber,
  submitOtpSubscriber,
} from 'utils/loginUtils/loginEventUtils';
import { getSpotCounsellingFlow } from 'utils/loginUtils/loginFlowUtils';
import { segmentEvents, trackIeltsEvent, trackSignup } from 'scripts/segment';

export enum FlowType {
  IELTS = 'IELTS',
  COUNSELLING = 'COUNSELLING',
  COMMON = 'COMMON',
}

const ieltsLoginConstant: LoginContent = {
  title: 'Take the first step, to fulfill your study abroad dreams',
  canChangeCountryCode: true,
};

const counsellingLoginConstant: LoginContent = {
  title: 'Counselling Flow',
};

const commonLoginConstant: LoginContent = {
  title: 'Enter your mobile number to continue',
};

const loginConstant: Record<FlowType, LoginContent> = {
  [FlowType.IELTS]: ieltsLoginConstant,
  [FlowType.COUNSELLING]: counsellingLoginConstant,
  [FlowType.COMMON]: commonLoginConstant,
};

export const getTrackingId = (url_string: string) => {
  try {
    const url = new URL(window.location.origin + url_string);
    return url.searchParams.get('tracking_id');
  } catch (_) {
    return;
  }
};

const useLogin = (flowType: FlowType = FlowType.IELTS) => {
  const router = useRouter();
  const path = router.pathname;
  const callbackUrl = router.query.callbackUrl as string;
  const [loginStatus, setLoginStatus] = useState(false);
  const counsellingStage = useSelector(
    (state: any) => state?.counselling?.counsellingStage,
  );

  useEffect(() => {
    loginEvent();
    getLoginStatus();
  }, []);

  const getLoginStatus = () => {
    const isLogin = leapLogin.isLogin();
    setLoginStatus(isLogin);
  };

  const eventParam = useMemo(() => getEventParams(callbackUrl), [callbackUrl]);

  const loginEvent = () => {
    leapLogin.subscribe(LOGIN_EVENTS.GENERATE_OTP, generateOtpSubscriber);
    leapLogin.subscribe(LOGIN_EVENTS.RESEND_OTP, resendOtpSubscriber);
    leapLogin.subscribe(LOGIN_EVENTS.SUBMIT_OTP, submitOtpSubscriber);
    leapLogin.subscribe(
      LOGIN_EVENTS.LOGIN_WITH_TC,
      loginWithTruecallerSubscriber,
    );
    leapLogin.subscribe(LOGIN_EVENTS.GENERATE_OTP_SUCCESS, (option) =>
      generateOtpSuccessSubscriber(option, eventParam),
    );
    leapLogin.subscribe(LOGIN_EVENTS.LOGIN_FAILURE, loginFailureSubscriber);
    leapLogin.subscribe(LOGIN_EVENTS.RESEND_OTP_SUCCESS, (option) =>
      resendOtpSuccessSubscriber(option, eventParam),
    );
  };
  const registerUser = async ({
    token,
    phone,
    country,
  }: {
    token: string;
    phone?: string;
    country?: string;
  }) => {
    leapLogin.loading(true);
    const registerRes = await loginApis.registerUser(flowType);
    const trackPayload = {
      pageName: path || 'NA',
      authType: registerRes?.data?.newUser ? 'Sign Up' : 'Sign In',
      leadStage: registerRes?.data?.stage || '',
      flowType: flowType,
      userId: registerRes?.data?.userId || '',
      phone: phone || 'NA',
      country,
    };
    if (registerRes?.success) {
      saveCookie(null, 'user_id', registerRes?.data?.userId || '');
      await trackSignup('Log In Successful', trackPayload);
      setLoginStatus(true);
      leapLogin.loading(false);
      return { status: true };
    } else {
      trackIeltsEvent(segmentEvents.OTP_ERROR, {
        phoneNumber: phone,
        errorCode: registerRes?.response?.status || 200,
        errorMessage: registerRes?.message || '',
        currentPage: window.location.pathname,
        refererUrl: document?.referrer,
        loginStep: 'REGISTER_USER',
      });
      throw 'Something went wrong';
    }
  };
  ('');

  const postLoginAction = async ({
    token,
    phone,
    country,
  }: {
    token: string;
    phone?: string;
    country?: string;
  }): Promise<any> => {
    return registerUser({ token, phone, country }).catch((err) => {
      leapLogin.loading(false);
      deleteCookie(null, 'token');
      leapLogin.error(err);
      return { status: false, data: err };
    });
  };

  const initLogin = (
    loginOption: LoginContent = {},
    userRegister?: (isNewUser: boolean) => void,
  ) => {
    const loginObj: LoginContent = {
      ...loginConstant[flowType],
      ...loginOption,
      callback: async (token, option) => {
        const postLoginStatus = await postLoginAction({
          token,
          phone: option?.phone,
          country: option?.country,
        });
        if (!postLoginStatus?.status) return postLoginStatus;
        if (loginOption.callback) await loginOption.callback(token, option);
        userRegister && userRegister(!!postLoginStatus?.data?.newUser);
        return { status: true };
      },
    };
    leapLogin(loginObj);
  };

  const spotCounsellingFlowRedirect = () => {
    const spotCounsellingPath = getSpotCounsellingFlow(counsellingStage);
    window.open(spotCounsellingPath, '_self');
  };

  const handleSpotCounsellingFlow = () => {
    if (loginStatus) {
      spotCounsellingFlowRedirect();
    } else {
      initLogin(
        {
          canChangeCountryCode: false,
          title: 'Sign Up To Get Free Counselling',
        },
        (isNewUser) => spotCounsellingFlowRedirect(),
      );
    }
  };

  return { loginStatus, initLogin, handleSpotCounsellingFlow };
};
export default useLogin;
