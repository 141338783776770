import { ReviewDataSchema } from 'Interfaces/reducerTypes/ReviewSchema';

export const reviewParser = (ieltsReview: Array<ReviewDataSchema>) => {
  ieltsReview = ieltsReview.filter((data: any) => data?.platform == 'IELTS');
  return ieltsReview.reduce((a: any, c: any) => {
    if (a[c.type] && a[c.type].length > 0) {
      a[c.type].push(c);
    } else {
      a[c.type] = [];
      a[c.type].push(c);
    }
    return a;
  }, {});
};

export const getTestimonialFromReviewData = (
  ieltsReview: Array<ReviewDataSchema>,
) => {
  try {
    return reviewParser(ieltsReview)['Testimonial'];
  } catch (e) {
    return [];
  }
};
