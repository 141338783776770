import api from 'services/api';

const registerUser = async (flowType: string) => {
  try {
    const res = await api.post('/api/avataran-route/avataran_register', {
      flowType,
    });
    return res.data;
  } catch (err: any) {
    console.log(err);
    return err;
  }
};

export const loginApis = {
  registerUser,
};
