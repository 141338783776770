import { call, put, takeLatest } from "@redux-saga/core/effects";
import { CourseInclusionApis } from "components/Ielts/IeltsPremiumCourseInclusions/redux/InclusionsApi";
import {
  fetchGetIeltsCourseInclusions,
  fetchGetIeltsCourseInclusionsError,
  fetchGetIeltsCourseInclusionsSuccess,
  setIeltsCourseInclusionsLoading,
} from "components/Ielts/IeltsPremiumCourseInclusions/redux/InclusionsSlice";

function* fetchGetIeltsCourseInclusionsSaga(): Generator<any, any, any> {
  yield put(setIeltsCourseInclusionsLoading(true));
  try {
    const res = yield call(CourseInclusionApis.fetchInclusions);
    if (Array.isArray(res)) {
      yield put(fetchGetIeltsCourseInclusionsSuccess(res[0].CourseConclusion));
    }
  } catch (error) {
    yield put(
      fetchGetIeltsCourseInclusionsError(
        "Something went wrong. Please try after sometime"
      )
    );
  }
}

export default function* CourseInclusionSaga() {
  yield takeLatest(
    fetchGetIeltsCourseInclusions,
    fetchGetIeltsCourseInclusionsSaga
  );
}
