import { AnyAction } from 'redux';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ieltsPlusApi } from 'components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusApi';
import { ieltsPlusActions } from 'components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusActions';
import {
  ieltsBatchSorting,
  reduceOnlineWorkshopEnrollment,
} from 'utils/batches';
import { errorConstants } from 'DataMapper/errorConstants';
import { isFunction } from 'utils/utils';

const getCompletedBatches = (data: any) => {
  const completedBatches = data.filter(
    (batch: any) => new Date(batch.endTime) < new Date(),
  );
  return completedBatches;
};

const getUpcomingBatches = (data: any) => {
  const upcomingBatches = data.filter(
    (batch: any) => new Date(batch.endTime) > new Date(),
  );
  return upcomingBatches;
};

function* fetchData(): Generator<any, any, any> {
  try {
    const ieltsData = yield call(ieltsPlusApi.fetchIeltsPlusData);
    yield put(ieltsPlusActions.fetchSuccess(ieltsData));
  } catch (e: any) {
    console.error(e);
    yield put(ieltsPlusActions.fetchFail(e.message));
  }
}

function* enrollToIeltsPlus(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(
      ieltsPlusApi.enrollStudentToIeltsPlus,
      action.payload,
    );
    yield put(ieltsPlusActions.enrollSuccess(res));
  } catch (e: any) {
    console.error(e);
    yield put(ieltsPlusActions.enrollFail(e.message));
  }
}

function* fetchObjects(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsPlusApi.fetchObjects, action.payload);
    if (res.data.success) {
      yield put(ieltsPlusActions.fetchObjectsSuccess(res.data.data));
    } else {
      yield put(ieltsPlusActions.fetchObjectsFail(res.data.message));
    }
  } catch (e: any) {
    console.error(e);
    yield put(ieltsPlusActions.fetchObjectsFail(e.message));
  }
}

function* fetchEnrolledBatches(): Generator<any, any, any> {
  try {
    const res = yield call(ieltsPlusApi.fetchEnrolledBatches);
    if (res.success) {
      const enrolledBatches = JSON.parse(
        JSON.stringify(res.data.enrolledBatches),
      );
      enrolledBatches.addedToCalendar = false;
      const completedBatches = getCompletedBatches(enrolledBatches);
      const upcomingBatches = getUpcomingBatches(enrolledBatches);
      yield put(ieltsPlusActions.addCompletedIeltsClasses(completedBatches));
      yield put(
        ieltsPlusActions.addUpcomingIeltsClasses(
          ieltsBatchSorting(upcomingBatches),
        ),
      );
      yield put(
        ieltsPlusActions.fetchEnrolledBatchesSuccess(
          ieltsBatchSorting(enrolledBatches),
        ),
      );
      const batchList = [...upcomingBatches, ...completedBatches];
      const { plusEnrollment, foundationEnrolment, premiumEnrolment } =
        batchList.reduce(reduceOnlineWorkshopEnrollment, {
          plusEnrollment: 0,
          foundationEnrolment: 0,
          premiumEnrolment: 0,
        });
      yield put(
        ieltsPlusActions.checkIeltsEnrollment(enrolledBatches.length > 0),
      );
      yield put(
        ieltsPlusActions.checkFoundationEnrollment(foundationEnrolment > 0),
      );
      yield put(
        ieltsPlusActions.checkPlusEnrollment(
          plusEnrollment > 0 || premiumEnrolment > 0,
        ),
      );
      yield put(ieltsPlusActions.checkPremiumEnrollment(premiumEnrolment > 0));
    } else {
      yield put(ieltsPlusActions.updateError(res.message));
    }
  } catch (e: any) {
    console.error(e);
    yield put(
      ieltsPlusActions.updateError(
        'Something went wrong. Please try after sometime',
      ),
    );
  } finally {
    yield put(ieltsPlusActions.toggleLoadingEnrolledClasses(false));
  }
}

function* fetchRewardList(action: AnyAction): Generator<any, any, any> {
  try {
    const res = yield call(ieltsPlusApi.fetchRewardList, action.payload);
    const sortdata = res.data?.sort((a: any, b: any) =>
      a.reward.nthDay > b.reward.nthDay ? 1 : -1,
    );
    yield put(ieltsPlusActions.fetchRewardListSuccess(sortdata));
  } catch (e: any) {
    console.error(e);
    yield put(ieltsPlusActions.enrollFail(e.message));
  }
}
function* fetchStudentDetailsSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const { data: res } = yield call(ieltsPlusApi.fetchStudentDetails);
    if (res.success) {
      if (isFunction(action.payload)) {
        yield action.payload(res?.data);
      }
      yield put(ieltsPlusActions.fetchStudentDetailsSuccess(res?.data));
    } else {
      yield put(
        ieltsPlusActions.fetchFail(
          res?.message || errorConstants.SOMETHING_WENT_WRONG,
        ),
      );
      throw new Error(res?.message || errorConstants.SOMETHING_WENT_WRONG);
    }
  } catch (error: any) {
    yield put(
      ieltsPlusActions.fetchFail(
        error?.message || errorConstants.SOMETHING_WENT_WRONG,
      ),
    );
    console.log(error);
  }
}
function* fetchQeFormDataSaga(action: AnyAction): Generator<any, any, any> {
  try {
    const { data: res } = yield call(ieltsPlusApi.fetchQeFormDataApi);
    if (res.success) {
      if (isFunction(action.payload)) {
        action.payload(res?.data);
      }
      yield put(ieltsPlusActions.fetchQeFormDataSuccess(res?.data));
    } else {
      throw new Error(res?.message || errorConstants.SOMETHING_WENT_WRONG);
    }
  } catch (error: any) {
    console.log(error);
  }
}

function* ieltsSaga() {
  yield takeLatest('IELTS_FETCH_REQUESTED', fetchData);
  yield takeLatest('ENROLL_TO_IELTS_PLUS', enrollToIeltsPlus);
  yield takeLatest('FETCH_OBJECTS_REQUESTED', fetchObjects);
  yield takeLatest('FETCH_ENROLLED_BATCHES_REQUESTED', fetchEnrolledBatches);
  yield takeLatest('FETCH_REWARD_LIST_REQUESTED', fetchRewardList);
  yield takeLatest('FETCH_STUDENT_DETAILS', fetchStudentDetailsSaga);
  yield takeLatest('FETCH_QE_FORM_DATA', fetchQeFormDataSaga);
}

export default ieltsSaga;
