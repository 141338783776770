export const debounce = (callback: () => void, delay: number): (() => void) => {
  let timer: any = null;
  return () => {
    timer = setTimeout(() => {
      if (timer !== null) {
        callback();
        timer = null;
      }
    }, delay);
  };
};
export const nameValidator = (name: string) => {
  if (!name.trim()) {
    return false;
  }
  return /^[A-Za-z\s]+$/.test(name);
};

export const cloneDeep = (data: any) => JSON.parse(JSON.stringify(data));

export const isFunction = (data: any) => typeof data === 'function';
