import { all } from "redux-saga/effects";
import facultiesSaga from "components/Ielts/Faculty/redux/FacultySaga";
import UpcomingIeltsBatchesSaga from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsSaga";
import reviewSaga from "components/Ielts/Reviews/redux/ReviewsSaga";
import CourseInclusionSaga from "components/Ielts/IeltsPremiumCourseInclusions/redux/InclusionsSaga";
import ieltsPlusSaga from "components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusSaga";

function* rootSaga() {
  yield all([
    facultiesSaga(),
    UpcomingIeltsBatchesSaga(),
    reviewSaga(),
    CourseInclusionSaga(),
    ieltsPlusSaga(),
  ]);
}

export default rootSaga;
