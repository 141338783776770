import { useRouter } from "next/router";
import useDevice from "hooks/useDevice";

export enum platform {
  app = "App",
  mWeb = "Mweb",
  web = "WEB",
}

const usePlatform = (): platform => {
  const { isMobile } = useDevice();
  const router = useRouter();
  if (router.query["platform"] == "app") return platform.app;
  return isMobile ? platform.mWeb : platform.web;
};

export default usePlatform;
