import api from 'services/api';
import { generateRequestId, getInfoMessageApi } from 'winston/logUtils';
import { logger } from 'winston/winston.config';
const fetchReviews = async () => {
  try {
    const data = await api.get('/api/avataran-route/avataran_cms?path=reviews');
    return data.data;
  } catch (e: any) {
    console.log(e);
  }
};

const fetchReviewOnServer = async () => {
  try {
    const cmsServer = process.env.CMS_URL;
    const res = await api.get(`${cmsServer}/reviews`);
    if (res?.data) {
      return { success: true, data: res?.data };
    } else
      return {
        success: false,
        data: null,
      };
  } catch (e: any) {
    console.log(e);
    return {
      success: false,
      data: null,
    };
  }
};

const fetchSeoContent = async () => {
  const requestId = generateRequestId();
  const cmsServer = process.env.CMS_URL_COST;
  const apiUrl = `${cmsServer}/content-libraries?page_name=ielts_preparation_online`;
  try {
    logger.info(
      getInfoMessageApi({
        message: 'fetching ietls preparation online data from cms',
        path: apiUrl,
        requestId,
        apiHeaders: {},
        userId: 'NA',
      }),
    );
    const res = await api.get(apiUrl);
    if (res?.data) {
      const data = (res?.data?.length && res?.data[0]) || [];
      return { success: true, data };
    } else
      return {
        success: false,
        data: null,
      };
  } catch (e: any) {
    logger.error(
      getInfoMessageApi({
        requestId,
        message: `in avataran_auth method failed ${
          e?.response?.data?.message ||
          e?.message ||
          'failed to fetch data from cms'
        }`,
        path: apiUrl as string,
        statusCode: e?.response?.status,
      }),
    );
    return {
      success: false,
      data: null,
    };
  }
};

export const reviewApis = {
  fetchReviews,
  fetchReviewOnServer,
  fetchSeoContent,
};
