import api from 'services/api';
import { generateRequestId, getInfoMessageApi } from 'winston/logUtils';
import { logger } from 'winston/winston.config';
// import { toast } from "react-toastify";

const fetchFaculties = async () => {
  const requestId = generateRequestId();
  logger.info(
    getInfoMessageApi({
      message: 'teacher api started',
      path: `${process.env.SCHOLAR_URL}/teacher`,
      requestId,
      apiHeaders: {},
      userId: 'NA',
    }),
  );
  try {
    const res = await api.get(`${process.env.SCHOLAR_URL}/teacher`);
    if (res.data.success) {
      return res.data.data;
    } else {
      logger.error(
        getInfoMessageApi({
          requestId,
          path: `${process.env.SCHOLAR_URL}/teacher`,
          message: res?.data?.message,
          statusCode: res?.status,
          apiData: res?.data,
        }),
      );
      // toast.error(res.data.message);
    }
  } catch (err: any) {
    logger.error(
      getInfoMessageApi({
        requestId,
        path: `${process.env.SCHOLAR_URL}/teacher`,
        message: err?.response?.data?.message || err?.message,
        statusCode: err?.response?.status,
        apiData: err?.response?.data,
      }),
    );
    console.log(err);
  }
};

const fetchFacultiesClientSide = async () => {
  try {
    const res = await api.get(
      `/api/avataran-route/avataran_scholarRoute?path=teacher`,
    );
    if (res.data.success) {
      return res.data.data;
    } else {
      // toast.error(res.data.message);
    }
  } catch (err: any) {
    console.log(err);
    // toast.error("Something went wrong. Please try after sometime");
  }
};

export const facultyApis = {
  fetchFaculties,
  fetchFacultiesClientSide,
};
