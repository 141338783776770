import ReactGA from 'react-ga';

export const initGA = (userId?: string | number): void => {
  if (userId) {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_CODE as string, {
      gaOptions: {
        userId: userId as string,
      },
    });
  } else {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_CODE as string);
  }
};

export const logPageView = (path: string | undefined): void => {
  const page = path || window.location.pathname;
  ReactGA.pageview(page);
};

export const logEvent = (
  category: string,
  action: string,
  label: any,
): void => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const fireTagEvent = async (
  eventName: string,
  eventData?: { [key: string]: string },
): Promise<void> => {
  if ((window as any).dataLayer) {
    await (window as any).dataLayer.push({
      event: eventName,
      eventData: eventData,
    });
  }
};
