import { getUtmDataClient } from 'utils/utm_utils';
import { getCookie } from 'utils/cookie-utiils';
// import { osName } from "react-device-detect";
import timeZoneCityToCountry from 'tz-cities-to-countries.json';
import { getPlatform } from 'utils/events-utils';
import { mobileCheck } from 'utils/browserUtils';
declare global {
  interface Window {
    analytics: any;
  }
}

declare global {
  interface Document {
    document: any;
  }
}

let timestamp = 'NA';

const utmDetails = getUtmDataClient();

let userId = getCookie(null, 'user_id');

let userCountry: string;
try {
  if (Intl) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const tzArr = userTimeZone.split('/');
    const userCity = tzArr[tzArr.length - 1];
    userCountry =
      timeZoneCityToCountry[userCity as keyof typeof timeZoneCityToCountry];
  }
} catch (e) {
  console.log(e);
}

export const trackPage = (name: string, properties?: any) => {
  const eventName = 'Page Viewed';

  const props = {
    pageName: name || 'NA',
    pageUrl: window.location.href || 'NA',
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
    platform: mobileCheck() ? 'Mweb' : 'WEB',
    timestamp: timestamp,
    referrer: document?.referrer || 'NA',
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    country: userCountry || 'Na',
    // OS: osName,
    eventId: properties?.eventId || 'NA',
    formId: properties?.formId,
  };
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackPageV2 = (name: string, properties?: any) => {
  const eventName = 'Page Viewed';

  const props = {
    pageName: name || 'NA',
    pageUrl: window.location.href || 'NA',
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
    platform: mobileCheck() ? 'Mweb' : 'WEB',
    timestamp: timestamp,
    referrer: document?.referrer || 'NA',
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    country: userCountry || 'Na',
    // OS: osName,
    eventId: properties?.eventId || 'NA',
    Platform: getPlatform(),
    ...properties,
  };
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackPageViewWithContent = (name: string, props: any) => {
  const eventName = 'Page Viewed';
  const data = {
    pageName: name || 'NA',
    pageUrl: window.location.href || 'NA',
    contentName: props?.contentName || 'NA',
    contentFormat: props.contentFormat || 'NA',
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
    platform: mobileCheck() ? 'Mweb' : 'WEB',
    timestamp: timestamp,
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    country: userCountry || 'Na',
    // OS: osName,
    modalName: props?.modalName,
    applicationStage: props?.applicationStage,
    Platform: getPlatform(),
  };
  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackClick = async (name: any, props: any) => {
  try {
    const eventName = 'Click';
    const data = {
      pageName: name || 'NA',
      widgetFormat: props.widgetFormat || 'NA',
      widgetName: props.widgetName || 'NA',
      contentName: props.contentName || 'NA',
      contentFormat: props.contentFormat || 'NA',
      utmCampaign: utmDetails?.utmCampaign || 'NA',
      utmSource: utmDetails?.utmSource || 'NA',
      utmTerm: utmDetails?.utmTerm || 'NA',
      adId: utmDetails?.adId || 'NA',
      timestamp: timestamp || 'NA',
      from: props?.from || 'NA',
      phone: props?.phone || 'NA',
      cardNumber: props?.cardNumber || 'NA',
      platform: mobileCheck() ? 'Mweb' : 'WEB',
      sectionName: props?.sectionName || 'NA',
      eventCategory: props?.eventCategory || 'NA',
      screenHeight: window?.screen.height,
      screenWidth: window?.screen.width,
      // OS: osName,
      applicationStage: props?.applicationStage,
      pageUrl: window.location.href || 'NA',
      utmMedium: utmDetails?.utmMedium || 'NA',
    };

    if (window.analytics) {
      window.analytics.track(eventName, data);
    }
  } catch (e) {
    console.log('event err', e);
  }
};

export const trackIdentity = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.identify(eventName, props);
  }
};

export const trackIdentityV2 = async (eventName: string, props: any) => {
  try {
    if (window.analytics) {
      return fallBackAnalytics(
        new Promise((resolve, reject) => {
          window.analytics.identify(eventName, props, {}, () => {
            resolve(true);
          });
        }),
      );
    }
  } catch (e) {
    console.log('event err', e);
  }
};

export const trackEvent = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};
export const trackIeltsEvent = (eventName: string, props: any) => {
  try {
    props = {
      ...props,
      utmCampaign: utmDetails?.utmCampaign || 'NA',
      utmSource: utmDetails?.utmSource || 'NA',
      utmTerm: utmDetails?.utmTerm || 'NA',
      adId: utmDetails?.adId || 'NA',
      referrer: document?.referrer || 'NA',
      Platform: getPlatform(),
    };
    if (window.analytics) {
      window.analytics.track(eventName, props);
    }
  } catch (e) {
    console.log('event err', e);
  }
};

export const segmentEvents = {
  OTP_ERROR: 'OTP Error',
};

export const trackPayment = (eventName: string, props: any) => {
  if (window.analytics) {
    window.analytics.track(eventName, props);
  }
};

export const trackSignup = async (eventName: string, props: any) => {
  try {
    if (window.analytics) {
      const eventPayload = {
        pageName: window.location.href || 'NA',
        authType: props.authType || 'NA',
        utmCampaign: props.utmCampaign || 'NA',
        utmSource: props?.utmSource || 'NA',
        utmTerm: props?.utmTerm || 'NA',
        adId: props?.adId || 'NA',
        flowType: props.flowType || 'NA',
        subscribedToWhatsapp: props.subscribedToWhatsapp ? true : false,
        timestamp: timestamp,
        country: props?.country || 'NA',
        Platform: getPlatform(),
      };
      const userPayload = {
        pageName: window.location.href || 'NA',
        utmCampaign: props.utmCampaign || 'NA',
        utmSource: props?.utmSource || 'NA',
        utmTerm: props?.utmTerm || 'NA',
        adId: props?.adId || 'NA',
        flowType: props.flowType || 'NA',
        subscribedToWhatsapp: props?.subscribedToWhatsapp ? true : false,
        userId: props?.userId || 'NA',
        phone: props?.phone ? props?.phone : undefined,
        timestamp: timestamp,
        country: props?.country || 'NA',
      };
      window.analytics.identify(props.userId, userPayload);
      window.analytics.track(eventName, eventPayload);
    }
  } catch (e) {
    console.log('event err', e);
  }
};

export const trackForm = async (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      formType: props?.formType || 'NA',
      formId: props?.formId || 'NA',
      utmCampaign: utmDetails?.utmCampaign || 'NA',
      utmSource: utmDetails?.utmSource || 'NA',
      utmTerm: utmDetails?.utmTerm || 'NA',
      adId: utmDetails?.adId || 'NA',
      timestamp: timestamp,
    };

    const userPayload = {
      admitStatus:
        props?.admitDetails?.admitStatus || props?.admitStatus || 'NA',
      college: props?.college || 'NA',
      consultant: props?.consultant || 'NA',
      helpForAdmit: props?.helpForAdmit || 'NA',
      email: props?.email ? props?.email : undefined,
      gradeMetric: props?.gradeMetric || 'NA',
      grades: props?.grades || 'NA',
      highestLevelEducation: props?.highestLevelEducation || 'NA',
      ieltsStatus: props?.ieltsStatus || 'NA',
      journeyStatus: props?.journeyStatus || 'NA',
      payOfExpenses: props?.payOfExpenses || 'NA',
      preferredCountry: props?.preferredCountry || 'NA',
      preferredIntake: props?.preferredIntake || 'NA',
      preferredProgram: props?.preferredProgram || 'NA',
      timestamp: timestamp,
      ieltsType: props?.ieltsType || 'NA',
      ieltsPurpose: props?.ieltsPurpose || 'NA',
      ieltsMonth: props?.ieltsMonth || 'NA',
      ieltsYear: props?.ieltsYear || 'NA',
    };
    window.analytics.identify(userId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};

export const trackProduct = (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      ...props,
      timestamp: timestamp,
    };
    const userPayload = {
      eventCategory: props?.eventCategory || 'NA',
      productCategory: props?.productCategory || 'NA',
    };
    window.analytics.identify(userId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};

export const trackOnboardingProduct = (eventName: string, props: any) => {
  if (window.analytics) {
    const eventPayload = {
      ...props,
      timestamp: timestamp,
    };
    const userPayload = {};
    window.analytics.identify(userId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};

export const segmentReset = () => {
  if (window.analytics) {
    window.analytics.reset();
  }
};

export const trackIeltsClick = (name: any, props: any) => {
  try {
    const eventName = 'Click';
    //  // add a new field in the array below which is passed in a set
    const eventFields = [
      'pageName',
      'widgetFormat',
      'widgetName',
      'contentName',
      'contentFormat',
      'utmCampaign',
      'utmSource',
      'utmTerm',
      'adId',
      'timestamp',
      'eventId',
      'eventID2',
      'QuizScore',
      'eventCategory',
      'teacher',
      'startDate',
      'endDate',
      'title',
      'startTime',
      'endTime',
      'eventLink',
      'referrer',
      'sectionName',
      'filterName',
      'offerPrice',
      'Rewardstatus',
      'RewardDay',
      'RewardName',
      'Status',
      'contentPage',
      'product',
      'buttonName',
      'type',
      'EducatorName',
      'platform',
    ];
    let eventData: any = eventFields.reduce(
      (accumulator: any, currentValue) => {
        if (props[currentValue] || typeof props[currentValue] === 'number') {
          accumulator[currentValue] = props[currentValue];
        }
        return accumulator;
      },
      {},
    );

    const defaultFields = {
      pageName: name || 'NA',
      referrer: document?.referrer || 'NA',
      utmCampaign: utmDetails?.utmCampaign || 'NA',
      utmSource: utmDetails?.utmSource || 'NA',
      utmTerm: utmDetails?.utmTerm || 'NA',
      adId: utmDetails?.adId || 'NA',
      timestamp: timestamp || 'NA',
      screenHeight: window?.screen.height,
      screenWidth: window?.screen.width,
      // OS: osName,
      Platform: getPlatform(),
      platform: mobileCheck() ? 'Mweb' : 'WEB',
    };
    eventData = { ...eventData, ...defaultFields };
    if (window.analytics) {
      window.analytics.track(eventName, eventData);
    }
  } catch (e) {
    console.log('event err', e);
  }
};

export const trackEventsClick = (name: any, props: any) => {
  const eventName = 'Click';
  const data = {
    pageName: name || 'NA',
    widgetFormat: props?.widgetFormat || 'NA',
    widgetName: props?.widgetName || 'NA',
    contentName: props?.contentName || 'NA',
    contentFormat: props?.contentFormat || 'NA',
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
    timestamp: timestamp || 'NA',
    eventId: `${props?.eventId || 'NA'}`,
    eventTags: props?.tags || 'NA',
    eventCTA: props?.cta || 'NA',
    referrer: document?.referrer || 'NA',
    currentPage: window?.location?.pathname || 'NA',
    screenHeight: window?.screen.height,
    screenWidth: window?.screen.width,
    // OS: osName,
    Platform: getPlatform(),
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackIeltsEventAttend = (props: any) => {
  const eventName = 'Event Attended';
  const data = {
    eventId: `${props?.eventId || 'NA'}`,
    eventCategory: props?.eventCategory || 'NA',
    teacher: props?.teacher || 'NA',
    meetingDate: props?.meetingDate || 'NA',
    title: props?.title || 'NA',
    meetingTime: props?.meetingTime || 'NA',
    eventLink: props?.eventLink || 'NA',
    batchDay: props?.batchDay || 'NA',
    Platform: getPlatform(),
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackIeltsForm = async (eventName: string, props: any) => {
  let email = undefined;
  if (props.email) email = props.email;
  else if (props.emailId) email = props.emailId;
  if (window.analytics) {
    const eventPayload = {
      formType: props?.formType || 'NA',
      eventId: `${props?.eventId || 'NA'}`,
      pageName: props?.pageName || 'NA',
      widgetFormat: props?.widgetFormat || 'NA',
      widgetName: props?.widgetName || 'NA',
      contentName: props?.contentName || 'NA',
      contentFormat: props?.contentFormat || 'NA',
      utmCampaign: utmDetails?.utmCampaign || 'NA',
      utmSource: utmDetails?.utmSource || 'NA',
      utmTerm: utmDetails?.utmTerm || 'NA',
      adId: utmDetails?.adId || 'NA',
      rating: `${props.rating || 'NA'}`,
      timestamp: timestamp,
      referrer: document?.referrer || 'NA',
      Platform: getPlatform(),
    };

    const userPayload = {
      college: props?.college || 'NA',
      email,
      highestLevelEducation: props?.highestLevelEducation || 'NA',
      ieltsStatus: props?.ieltsStatus || 'NA',
      journeyStatus: props?.journeyStatus || 'NA',
      payOfExpenses: props?.payOfExpenses || 'NA',
      preferredCountry: props?.preferredCountry || 'NA',
      preferredIntake: props?.preferredIntake || 'NA',
      preferredProgram: props?.preferredProgram || 'NA',
      timestamp: timestamp,
      currentEnglishLevel: props?.currentEnglishLevel || 'NA',
      ieltsExamMonth: props?.ieltsExamMonth || 'NA',
      prefferedTimeSlot: props?.prefferedTimeSlot || 'NA',
    };
    window.analytics.identify(userId, userPayload);
    window.analytics.track(eventName, eventPayload);
  }
};

export const trackIeltsPayment = (props: any) => {
  if (window.analytics) {
    window.analytics.track('Payment Successful', {
      ...props,
      utm_campaign: utmDetails?.utmCampaign || 'NA',
      utm_Source: utmDetails?.utmSource || 'NA',
      utm_term: utmDetails?.utmTerm || 'NA',
      ad_id: utmDetails?.adId || 'NA',
      timestamp: timestamp,
      referrer: document?.referrer || 'NA',
      Platform: getPlatform(),
    });
  }
};

export const trackIeltsError = (props: any) => {
  const eventName = 'Error';
  const data = {
    eventId: `${props?.eventId || 'NA'}`,
    eventCategory: props?.eventCategory || 'NA',
    meetingDate: props?.meetingDate || 'NA',
    title: props?.title || 'NA',
    teacher: props?.teacher || 'NA',
    meetingTime: props?.meetingTime || 'NA',
    eventLink: props?.eventLink || 'NA',
    errorCategory: props?.errorCategory || 'NA',
    errorMessage: props?.errorMessage || 'NA',
    Platform: getPlatform(),
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackFileUpload = (name: string, props: any) => {
  const eventName = 'Document Uploaded';
  const data = {
    pageName: name || 'NA',
    fileType: props?.fileType || 'NA',
    fileCategory: props?.fileCategory || 'NA',
    fileName: props?.fileName || 'NA',
    uploadSource: props?.uploadSource || 'NA',
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
    referrer: document?.referrer || 'NA',
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackVideo = (props: any) => {
  const eventName = 'Watch Video';
  const data = {
    videoName: props.videoName || 'NA',
    videoId: props.videoId || 'NA',
    videoUrl: props.videoUrl || 'NA',
    watchDuration: props.watchDuration || 'NA',
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
    timestamp: timestamp || 'NA',
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackCalendarMeetingEvent = (name: any, props: any) => {
  const eventName = props.eventName || '';
  const data = {
    EventCategory: 'Office Hour',
    EventID: 'NA',
    Counsellor: props.CounsellorName,
    Speaker: 'NA',
    Host: 'NA',
    EventDate: props.date,
    EventTime: props.time,
    utmCampaign: utmDetails?.utmCampaign || 'NA',
    utmSource: utmDetails?.utmSource || 'NA',
    utmTerm: utmDetails?.utmTerm || 'NA',
    adId: utmDetails?.adId || 'NA',
  };

  if (window.analytics) {
    window.analytics.track(eventName, data);
  }
};

export const trackIeltsFormAsync = (
  eventName: string,
  props: any,
): Promise<any> => {
  return new Promise(async (resolve) => {
    try {
      let email = undefined;
      if (props.email) email = props.email;
      else if (props.emailId) email = props.emailId;
      const eventPayload = {
        formType: props?.formType || 'NA',
        eventId: `${props?.eventId || 'NA'}`,
        pageName: props?.pageName || 'NA',
        widgetFormat: props?.widgetFormat || 'NA',
        widgetName: props?.widgetName || 'NA',
        contentName: props?.contentName || 'NA',
        contentFormat: props?.contentFormat || 'NA',
        utmCampaign: utmDetails?.utmCampaign || 'NA',
        utmSource: utmDetails?.utmSource || 'NA',
        utmTerm: utmDetails?.utmTerm || 'NA',
        gclid: utmDetails?.gclid || 'NA',
        adName: utmDetails?.adName || 'NA',
        adId: utmDetails?.adId || 'NA',
        rating: `${props.rating || 'NA'}`,
        timestamp: timestamp,
        referrer: document?.referrer || 'NA',
        Platform: getPlatform(),
        formId: props.formId || 'NA',
        leadSource: props.leadSource || 'NA',
        ...props,
      };

      const userPayload = {
        college: props?.college || 'NA',
        email,
        highestLevelEducation: props?.highestLevelEducation || 'NA',
        ieltsStatus: props?.ieltsStatus || 'NA',
        journeyStatus: props?.journeyStatus || 'NA',
        payOfExpenses: props?.payOfExpenses || 'NA',
        preferredCountry: props?.preferredCountry || 'NA',
        preferredIntake: props?.preferredIntake || 'NA',
        preferredProgram: props?.preferredProgram || 'NA',
        timestamp: timestamp,
        currentEnglishLevel: props?.currentEnglishLevel || 'NA',
        ieltsExamMonth: props?.ieltsExamMonth || 'NA',
        prefferedTimeSlot: props?.prefferedTimeSlot || 'NA',
        name: props?.name ? props?.name : undefined,
        ieltsExamType: props?.ieltsExamType || 'NA',
        whatCareerStage: props?.whatCareerStage || 'NA',
        whenIelts: props?.whenIelts || 'NA',
        phone: props?.phone ? props?.phone : undefined,
      };
      if (window.analytics) {
        await Promise.all([
          trackIdentityV2(userId, userPayload),
          trackEventAsync(eventName, eventPayload),
        ]);
        resolve(true);
      }
    } catch (e) {
      resolve(true);
    }
  });
};

export const trackEventAsync = async (name: any, props?: any) => {
  const eventName = name || '';
  if (window.analytics) {
    return fallBackAnalytics(
      new Promise((resolve) => {
        try {
          window.analytics.track(
            eventName,
            {
              ...props,
              utmCampaign: utmDetails?.utmCampaign || 'NA',
              utmSource: utmDetails?.utmSource || 'NA',
              utmTerm: utmDetails?.utmTerm || 'NA',
              gclid: utmDetails?.gclid || 'NA',
              adName: utmDetails?.adName || 'NA',
              utmMedium: utmDetails?.utmMedium || 'NA',
              adId: utmDetails?.adId || 'NA',
              Platform: getPlatform(),
              platform: getPlatform(),
            },
            {},
            () => {
              resolve(true);
            },
          );
        } catch (e) {
          resolve(true);
        }
      }),
    );
  }
};

const fallBackAnalytics = (cb: Promise<boolean>) =>
  new Promise((resolve) => {
    const timer = setTimeout(() => {
      resolve(true);
      clearTimeout(timer);
    }, 600);
    cb.then(() => {
      resolve(true);
      clearTimeout(timer);
    });
  });
