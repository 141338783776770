import { combineReducers } from "redux";

import FacultyReducer from "components/Ielts/Faculty/redux/FacultyReducer";
import UpcomingIeltsBatchesReducer from "components/Ielts/UpcomingIeltsBatches/redux/UpcomingIeltsReducer";
import AuthReducer from "components/decorator/redux/AuthReducer";
import ReviewReducer from "components/Ielts/Reviews/redux/ReviewsReducer";
import ieltsCourseInclusionsReducer from "components/Ielts/IeltsPremiumCourseInclusions/redux/InclusionsSlice";
import ieltsPlusReducer from "components/Profile/IeltsProfile/IeltsPlus/redux/IeltsPlusReducer";

export const rootReducer = combineReducers({
  auth: AuthReducer,
  ieltsFaculty: FacultyReducer,
  upcomingIeltsBatches: UpcomingIeltsBatchesReducer,
  review: ReviewReducer,
  ieltsCourseInclusions: ieltsCourseInclusionsReducer,
  ieltsPlus: ieltsPlusReducer,
});
