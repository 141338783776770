import {
  BundleCategoryDto,
  priceBundleName,
} from 'DataMapper/Common/WorkshopData';
import { PaymentType } from 'Interfaces/common/PaymentSchema';
import { BatchSchema } from 'Interfaces/reducerTypes/BatchDetailsSchema';
import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema';
import { getFullDate } from 'utils/ielts_date_utils';
import isValidDate from 'date-fns/isValid';

export const getBatchSlots = (startHours: number) => {
  if (startHours < 12) {
    return 'Morning Batch';
  } else if (startHours < 18) {
    return 'Afternoon Batch';
  } else {
    return 'Evening Batch';
  }
};

export const shortenWeeklySchedule = (weekday: string) => {
  // expected monday-friday
  // output mon-fri
  const weekArray = weekday.split('-');
  if (weekArray.length !== 2) return weekday;
  return `${weekArray[0].slice(0, 3)}-${weekArray[1].slice(0, 3)}`;
};

export const diffWeeks = (startDate: string, endDate: string) => {
  const dt1 = new Date(startDate);
  const dt2 = new Date(endDate);

  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60 * 24 * 7;
  return Math.abs(Math.round(diff));
};

export const getStartingHour = (startDate: string) => {
  return Number(new Date(startDate).getHours());
};

export const diffeDays = (
  startDate: string,
  hours: number,
  minutes: number,
) => {
  const startHours = hours < 10 ? `${0}${hours}` : `${hours}`;
  const startMinutes = minutes < 10 ? `${0}${minutes}` : `${minutes}`;
  const DateString =
    startDate + 'T' + startHours + ':' + startMinutes + ':' + '00';
  return getDayWithoutWeekend(DateString);
};

const getDayWithoutWeekend = (startDate: string) => {
  let ONE_DAY = 1000 * 60 * 60 * 24;
  let variableDate = Date.parse(startDate);
  let currentDate = Date.parse(new Date().toString());
  let totalDate = 0;
  while (variableDate <= currentDate) {
    let currentDayNumber = new Date(variableDate).getDay();
    if (currentDayNumber !== 0 && currentDayNumber !== 6) {
      totalDate += 1;
    }
    variableDate += ONE_DAY;
  }
  return totalDate;
};

export const dayDifference = (startDate: string) => {
  return getDayWithoutWeekend(startDate);
};

export const getStartingDate = (startDate: string) => {
  return Number(new Date(startDate).getDate());
};

export const isPremiumBatch = (batchData: any) => {
  return isPremiumBundleCheck(batchData?.pricingBundleName);
};

export const isPremiumBundleCheck = (
  pricingBundleName: priceBundleName | string,
): boolean => {
  return (
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM ||
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_2 ||
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_3 ||
    pricingBundleName === priceBundleName.IELTS_SELECT ||
    pricingBundleName === priceBundleName.IELTS_ELITE
  );
};

export const getPriceBundleNameFromPaymentType = (
  paymentType: PaymentType | string,
) => {
  switch (paymentType) {
    case PaymentType.IELTS_FOUNDATION_FEE:
      return priceBundleName.IELTS_FOUNDATION;
    case PaymentType.IELTS_PREMIUM_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM;
    case PaymentType.IELTS_PREMIUM_2_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM_2;
    case PaymentType.IELTS_PREMIUM_3_FEE:
      return priceBundleName.IELTS_PLUS_PREMIUM_3;
    case PaymentType.REWARD_BOOSTER_FEE:
      return priceBundleName.BOOSTER_PACK;
    case PaymentType.IELTS_PLUS_PART_2_FEE:
      return priceBundleName.IELTS_PLUS_PART_2;
    case PaymentType.IELTS_FEE:
    default:
      return priceBundleName.IELTS_PLUS;
  }
};

const isBatchFull = (bookedSeat: number, numberOfSeat: number) => {
  return numberOfSeat <= bookedSeat;
};

const classAlreadyStarted = (classStartDate: Date) => {
  return classStartDate < new Date();
};

export const getBatchErrorMessage = (batchDetails: BatchSchema) => {
  if (isBatchFull(batchDetails.seatBooked, batchDetails.numberOfSeats))
    return 'Sorry you cannot enrol in this batch as all seats are booked.';
  if (
    classAlreadyStarted(
      getFullDate(
        batchDetails.startDate,
        batchDetails.startHour,
        batchDetails.startMinute,
      ),
    )
  )
    return 'Sorry, You cannot enrol in this batch as it has already started.';
  return '';
};

export const removeNullValueFromArray = (arr: Array<any>) =>
  arr?.filter((data) => data);

export const selectOtherCountryIfGermanyIsThere = (arr: Array<any>) => {
  if (arr.length > 1 && arr.includes('Other')) {
    const index = arr.indexOf('Other');
    arr.splice(index, 1);
    return arr;
  } else return arr;
};

export const isIeltsPlusPart2Batch = (batchData: WorkshopSchema): boolean => {
  if (batchData) {
    return isIeltsPlusPart2BatchBundle(batchData.pricingBundleName);
  }
  return false;
};

export const isIeltsPlusPart2BatchBundle = (
  pricingBundleName: string | null | undefined,
): boolean => {
  return pricingBundleName === priceBundleName.IELTS_PLUS_PART_2;
};

export const checkIfIeltsPlusPart2BatchExistsInEnrolledBatches = (
  enrolledBatches: WorkshopSchema[],
) => {
  const part2Batch = enrolledBatches?.find((item) =>
    isIeltsPlusPart2BatchBundle(item.pricingBundleName),
  );
  if (part2Batch) return true;
  return false;
};

export const checkToShowPlusDisableMentModal = (
  batchData: BatchSchema,
  isEnrolledForIelts: boolean | null,
  enrolledBatches: WorkshopSchema[],
): boolean => {
  if (
    (batchData.pricingBundleName &&
      isPremiumBundleCheck(batchData.pricingBundleName)) ||
    isIeltsPlusPart2BatchBundle(batchData.pricingBundleName)
  ) {
    if (isIeltsPlusPart2BatchBundle(batchData.pricingBundleName)) {
      if (checkIfIeltsPlusPart2BatchExistsInEnrolledBatches(enrolledBatches)) {
        return true;
      }
    }
    return false;
  }
  if (isEnrolledForIelts) {
    return true;
  }
  return false;
};

export const giveNewPriceAfterApplyingDiscount = (
  oldPrice: number,
  discount: number,
): number => {
  return Math.floor(oldPrice - (discount / 100) * oldPrice);
};

export const isIeltsEliteBundle = (
  pricingBundleName: priceBundleName | string,
): boolean => {
  return (
    pricingBundleName === priceBundleName.IELTS_PLUS_PREMIUM_3 ||
    pricingBundleName === priceBundleName.IELTS_ELITE ||
    pricingBundleName.toUpperCase().includes('ELITE')
  );
};

export const isIeltsPrimeBundle = (bundleCategory?: BundleCategoryDto) =>
  bundleCategory == BundleCategoryDto.ELITE;

export const isIeltsSelectBundle = (bundleCategory?: BundleCategoryDto) =>
  bundleCategory == BundleCategoryDto.SELECT;

export const scrollToIeltsDemoForm = (userDetailsFormRef: any) => {
  userDetailsFormRef.current?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });
};
export const getEliteAndSelectBatches = (
  batches: Array<WorkshopSchema>,
): {
  selectBatch: Array<WorkshopSchema>;
  eliteBatch: Array<WorkshopSchema>;
} =>
  batches.reduce<{
    eliteBatch: Array<WorkshopSchema>;
    selectBatch: Array<WorkshopSchema>;
  }>(
    (acc, batch) => {
      if (isIeltsPrimeBundle(batch.bundleCategory || '')) {
        return { ...acc, eliteBatch: [...acc.eliteBatch, batch] };
      } else if (isIeltsSelectBundle(batch.bundleCategory || '')) {
        return { ...acc, selectBatch: [...acc.selectBatch, batch] };
      } else return acc;
    },
    {
      eliteBatch: [],
      selectBatch: [],
    },
  );

const addISTLabelToTime = (dateString: string) => {
  const istLabelString = dateString + '.000+05:30';
  return isValidDate(new Date(istLabelString)) ? istLabelString : dateString;
};

export const getLatestUpcomingClass = (
  batches: Array<WorkshopSchema>,
): WorkshopSchema | null => {
  try {
    if (batches.length < 1) return null;
    let nextUpcomingClass: WorkshopSchema | null = null;
    batches.forEach((batch) => {
      if (
        new Date(addISTLabelToTime(batch.startTime)) > new Date() &&
        (!nextUpcomingClass ||
          new Date(addISTLabelToTime(batch.startTime)) <
            new Date(addISTLabelToTime(nextUpcomingClass?.startTime)))
      ) {
        nextUpcomingClass = batch;
      }
    });
    return nextUpcomingClass;
  } catch (e) {
    return null;
  }
};
