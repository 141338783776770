import { WorkshopSchema } from 'Interfaces/reducerTypes/IeltsPlusSchema';
const calcPercentage = (value: number, total: number) => {
  return (value / total) * 100;
};

const batchSortingByStartTiming = (batches: Array<WorkshopSchema>) =>
  [...batches].sort((a, b) =>
    new Date(b.startTime) < new Date(a.startTime) ? 1 : -1,
  );

export const sortBatches = (batches: Array<WorkshopSchema>) => {
  const batchList = JSON.parse(JSON.stringify(batches));
  batchList.sort((a: WorkshopSchema, b: WorkshopSchema) => {
    return a.meetingStartTime > b.meetingStartTime ? 1 : -1;
  });
  const fullBatches = batchList.filter((batch: WorkshopSchema) => {
    const { numberOfSeats, seatBooked } = batch;
    const seatsRemaining = numberOfSeats - seatBooked;
    return seatsRemaining <= 0;
  });
  const fastFillingBatches = batchList.filter((batch: WorkshopSchema) => {
    const { numberOfSeats, seatBooked } = batch;
    const seatsRemaining = numberOfSeats - seatBooked;
    const percentageSeatRemaining = calcPercentage(
      seatsRemaining,
      numberOfSeats,
    );
    return percentageSeatRemaining <= 50 && percentageSeatRemaining > 0;
  });
  const normalBatches = batchList.filter((batch: WorkshopSchema) => {
    const { numberOfSeats, seatBooked } = batch;
    const allSeatsBooked = numberOfSeats - seatBooked <= 0;
    const fastFillingBatch =
      calcPercentage(numberOfSeats - seatBooked, numberOfSeats) <= 50 &&
      calcPercentage(numberOfSeats - seatBooked, numberOfSeats) > 0;
    return !allSeatsBooked && !fastFillingBatch;
  });
  const newBatchList = [
    ...batchSortingByStartTiming(fastFillingBatches),
    ...batchSortingByStartTiming(normalBatches),
    ...batchSortingByStartTiming(fullBatches),
  ];
  return newBatchList;
};

export const filterUpcomingBatchesFromEnrolledBatches = (
  enrollIeltsBatches: WorkshopSchema[],
  upcomingIeltsBatchesData: WorkshopSchema[],
) => {
  return upcomingIeltsBatchesData.filter((value: WorkshopSchema) => {
    return !enrollIeltsBatches.find(
      (batch: any) => batch.batchId == value.batchId,
    );
  });
};
