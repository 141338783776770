export const batchType = {
  ONLINE_WORKSHOP: 'ONLINE_WORKSHOP',
  MASTER_CLASS: 'MASTER_CLASS',
  SPEAKING_WORKSHOP: 'SPEAKING_WORKSHOP',
  ESSAY_EVALUATION: 'ESSAY_EVALUATION',
  DOUBT_WORKSHOP: 'DOUBT_WORKSHOP',
  FAST_TRACK_EXTRA_CLASSES: 'FAST_TRACK_EXTRA_CLASSES',
  BEGINNER_MASTER_CLASS: 'BEGINNER_MASTER_CLASS',
  FOUNDATION_ONLINE_WORKSHOP: 'FOUNDATION_ONLINE_WORKSHOP',
  ORIENTATION_WORKSHOP: 'IELTS_PLUS_ORIENTATION',
};

export enum priceBundleName {
  IELTS_FOUNDATION = 'IELTS_FOUNDATION',
  IELTS_PLUS = 'IELTS_PLUS',
  IELTS_PLUS_PREMIUM = 'IELTS_PLUS_PREMIUM',
  IELTS_PLUS_PREMIUM_2 = 'IELTS_PLUS_PREMIUM_2',
  IELTS_PLUS_PREMIUM_3 = 'IELTS_PLUS_PREMIUM_3',
  BOOSTER_PACK = 'BOOSTER_PACK',
  IELTS_MOCK_TEST_FEE = 'IELTS_MOCK_TEST_FEE',
  IELTS_PLUS_PART_2 = 'IELTS_PLUS_PART_II_V1',
  IELTS_FREE_MOCKTEST_FEE = 'IELTS_FREE_MOCKTEST_FEE',
  IELTS_PAID_MOCKTEST_FEE = 'IELTS_PAID_MOCKTEST_FEE',
  IELTS_SELECT = 'IELTS_SELECT',
  IELTS_ELITE = 'IELTS_ELITE',
  IELTS_ELITE_V1 = 'IELTS_ELITE_V1',
  IELTS_LIY_COURSE = 'IELTS_LIY_COURSE',
}

export enum BundleCategoryDto {
  SELECT = 'SELECT',
  ULTIMATE = 'ULTIMATE',
  ONE_TO_ONE = 'ONE_TO_ONE',
  ELITE = 'ELITE',
  GENERIC = 'GENERIC',
}
