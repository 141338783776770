import api from 'services/api';

const fetchInclusions = async () => {
  try {
    const data = await api.get(
      '/api/avataran-route/avataran_cms?path=ielts-premium-course-conclusions',
    );
    return data.data;
  } catch (e: any) {
    console.log(e);
  }
};

export const CourseInclusionApis = {
  fetchInclusions,
};
