import { AuthToken } from '@leapfinance/auth-kit';
import { counsellingStageRank } from 'DataMapper/Profile/StageMapper';
import { getTokenCookie } from 'lib/auth-cookies-new';
import { navRoutes } from 'page_routes';

export const getSpotCounsellingFlow = (counsellingStage: string) => {
  if (
    counsellingStage &&
    counsellingStageRank[counsellingStage] <=
      counsellingStageRank['LS_LEAD_CAPTURED']
  ) {
    return navRoutes.SPOT_COUNSELLING;
  } else {
    return navRoutes.POSTLOGIN_COUNSELLING;
  }
};

export const isLoggedIn = new AuthToken(getTokenCookie()).isValid;
