import { createAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ieltsCourseInclusionsState,
  ieltsCourseInclusionType,
} from "Interfaces/reducerTypes/IeltsCourseInclusions";
export const ieltsCourseInclusionsStateName = "ieltsCourseInclusions";

const initialState: ieltsCourseInclusionsState = {
  ieltsCourseInclusionsData: null,
  ieltsCourseInclusionsFetchError: "",
  ieltsCourseInclusionsLoading: false,
};

export const fetchGetIeltsCourseInclusions = createAction(
  `${ieltsCourseInclusionsStateName}/fetchGetIeltsCourseInclusions`
);

const IeltsCourseInclusionsSlice = createSlice({
  name: ieltsCourseInclusionsStateName,
  initialState,
  reducers: {
    fetchGetIeltsCourseInclusionsSuccess: (
      state,
      action: PayloadAction<ieltsCourseInclusionType[]>
    ) => ({
      ...state,
      ieltsCourseInclusionsData: action.payload,
    }),
    fetchGetIeltsCourseInclusionsError: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...state,
      ieltsCourseInclusionsFetchError: action.payload,
    }),
    setIeltsCourseInclusionsLoading: (
      state,
      action: PayloadAction<boolean>
    ) => ({
      ...state,
      ieltsCourseInclusionsLoading: action.payload,
    }),
  },
});
export const {
  fetchGetIeltsCourseInclusionsSuccess,
  fetchGetIeltsCourseInclusionsError,
  setIeltsCourseInclusionsLoading,
} = IeltsCourseInclusionsSlice.actions;

export default IeltsCourseInclusionsSlice.reducer;
