import {
  CookieReqContext,
  CookieResContext,
  getCookie,
  saveUtmCookie,
} from 'utils/cookie-utiils';

type UtmData = {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  campaignType?: string;
  adId?: string;
  trackingId?: string;
  gclid?: string;
  adName?: string;
  fbclid?: string;
  _fbc?: string;
  _fbp?: string;
};

export function getUtmData(ctx: CookieReqContext): UtmData | undefined {
  let utmSource,
    utmCampaign,
    utmTerm,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    fbclid,
    _fbc,
    _fbp;
  if (getCookie(ctx, 'utmSource')) {
    utmSource = getCookie(ctx, 'utmSource');
  }
  if (getCookie(ctx, 'utmCampaign')) {
    utmCampaign = getCookie(ctx, 'utmCampaign');
  }
  if (getCookie(ctx, 'utmMedium')) {
    utmMedium = getCookie(ctx, 'utmMedium');
  }
  utmTerm = getCookie(ctx, 'utmTerm');
  if (getCookie(ctx, 'campaignType')) {
    campaignType = getCookie(ctx, 'campaignType');
  }
  if (getCookie(ctx, 'adId')) {
    adId = getCookie(ctx, 'adId') as string;
  }
  if (getCookie(ctx, 'trackingId')) {
    trackingId = getCookie(ctx, 'trackingId');
  }
  if (getCookie(ctx, 'fbclid')) {
    fbclid = getCookie(ctx, 'fbclid') as string;
  }
  if (getCookie(ctx, '_fbc')) {
    _fbc = getCookie(ctx, '_fbc') as string;
  }
  if (getCookie(ctx, '_fbp')) {
    _fbp = getCookie(ctx, '_fbp') as string;
  }

  return {
    utmSource,
    utmCampaign,
    utmTerm,
    campaignType,
    adId,
    trackingId,
    utmMedium,
    fbclid,
    _fbc,
    _fbp,
  } as UtmData;
}

export function saveUtmData(ctx: CookieResContext, utmData: UtmData) {
  if (utmData.utmSource) {
    saveUtmCookie(ctx, 'utmSource', utmData.utmSource);
  }
  if (utmData.utmCampaign) {
    saveUtmCookie(ctx, 'utmCampaign', utmData.utmCampaign);
  }
  if (utmData.utmMedium) {
    saveUtmCookie(ctx, 'utmMedium', utmData.utmMedium);
  }
  if (utmData.utmTerm) {
    saveUtmCookie(ctx, 'utmTerm', utmData.utmTerm);
  }
  if (utmData.campaignType) {
    saveUtmCookie(ctx, 'campaignType', utmData.campaignType);
  }
  if (utmData.adId) {
    saveUtmCookie(ctx, 'adId', utmData.adId);
  }
  if (utmData.trackingId) {
    saveUtmCookie(ctx, 'trackingId', utmData.trackingId);
  }
  if (utmData.fbclid) {
    saveUtmCookie(ctx, 'fbclid', utmData.fbclid);
  }
}

export const getQueryParamStringFromObject = (object: any) => {
  let valuesString: any = Object.keys(object).map((key) =>
    object[key]?.length > 0 ? `${key}=${object[key]}` : ``,
  ) as string[];

  valuesString = valuesString?.filter((n: any) => n)?.join('&');
  valuesString = valuesString.length > 0 ? `&${valuesString}` : ``;
  return valuesString;
};

export const getUtmDataClient = (): UtmData => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const utmSource = params.get('utm_source') || getCookie(null, 'utmSource');
    const utmCampaign =
      params.get('utm_campaign') || getCookie(null, 'utmCampaign');
    const utmMedium = params.get('utm_medium') || getCookie(null, 'utmMedium');
    const utmTerm = params.get('utm_term') || getCookie(null, 'utmTerm');
    const campaignType =
      params.get('campaign_type') || getCookie(null, 'campaignType');
    const adId = params.get('ad_id') || getCookie(null, 'adId');
    const trackingId =
      params.get('tracking_id') || getCookie(null, 'trackingId');
    const fbclid = params.get('fbclid') || getCookie(null, 'fbclid');
    const _fbc = params.get('_fbc') || getCookie(null, '_fbc');
    const _fbp = params.get('_fbp') || getCookie(null, '_fbp');

    return {
      utmSource,
      utmCampaign,
      utmTerm,
      campaignType,
      adId,
      trackingId,
      utmMedium,
      fbclid,
      _fbc,
      _fbp,
    } as UtmData;
  }
  return {};
};
