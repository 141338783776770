import { imageBasePath } from "utils/imageUtils";

export interface ValuePropType {
  id: number;
  icon: string;
  titleText?: string;
  text: string;
  isDynamicText?: boolean;
  type?: string;
  altText: string;
}

export const valuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/live.svg`,
    altText: "Live Classes",
    titleText: "Daily",
    text: "Live Classes",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/check.svg`,
    altText: "Mock test",
    text: "Mock Tests",
    isDynamicText: true,
    type: "tests",
  },
  {
    id: 3,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/note.svg`,
    altText: "Evaluation",
    text: "Writing Evaluation",
    isDynamicText: true,
    type: "writing",
  },
  {
    id: 4,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/chat.svg`,
    altText: "Speaking",
    text: "Speaking Evaluation",
    isDynamicText: true,
    type: "speaking",
  },
  {
    id: 5,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/idea.svg`,
    altText: "Material",
    text: "Study Materials",
    isDynamicText: false,
    type: "material",
  },
  {
    id: 6,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/certificate.svg`,
    altText: "Certificate",
    text: "Completion Certificate",
    isDynamicText: false,
    type: "certificate",
  },
  {
    id: 7,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/abc.svg`,
    altText: "Daily Grammar classes",
    text: "Daily Grammar classes",
    isDynamicText: false,
    type: "daily_grammar_classes",
  },
  {
    id: 8,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/video.svg`,
    altText: "Class recordings",
    text: "Class recordings",
    isDynamicText: false,
    type: "Class recordings",
  },
];

export const MasterclassValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${imageBasePath}/avataran_assets/icons/ielts/live_classes.png`,
    altText: "Live Classes",
    text: "Live Classes",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${imageBasePath}/avataran_assets/icons/ielts/mock_test.svg`,
    altText: "Mock test",
    text: "Mock Tests",
    isDynamicText: false,
    type: "tests",
  },
  {
    id: 3,
    icon: `${imageBasePath}/avataran_assets/icons/ielts/writing_evaluation.svg`,
    altText: "Evaluation",
    text: "Writing Evaluation",
    isDynamicText: false,
    type: "writing",
  },
  {
    id: 4,
    icon: `${imageBasePath}/avataran_assets/icons/ielts/speaking_evaluation.svg`,
    altText: "Speaking",
    text: "Speaking Evaluation",
    isDynamicText: false,
    type: "speaking",
  },
  {
    id: 5,
    icon: `${imageBasePath}/avataran_assets/icons/ielts/study_material.svg`,
    altText: "Material",
    text: "Study Materials",
    isDynamicText: false,
    type: "material",
  },
  {
    id: 6,
    icon: `${imageBasePath}/avataran_assets/icons/ielts/certificate.svg`,
    altText: "Certificate",
    text: "Completion Certificate",
    isDynamicText: false,
    type: "certificate",
  },
];

export const ieltsPlusValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/live.svg`,
    altText: "20+ hours",
    titleText: "Daily",
    text: "Live Classes",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/check_list.svg`,
    titleText: "Five",
    altText: "Mock test",
    text: "Mock Tests",
    isDynamicText: false,
    type: "tests",
  },
  {
    id: 3,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/write.svg`,
    titleText: "Three",
    altText: "Evaluation",
    text: "Writing Evaluation",
    isDynamicText: false,
    type: "writing",
  },
  {
    id: 4,
    icon: `${imageBasePath}/avataran_assets/images/ielts/value_props/text.svg`,
    titleText: "Three",
    altText: "Speaking",
    text: "Speaking Evaluation",
    isDynamicText: false,
    type: "speaking",
  },
];

export const ieltsPremiumValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    altText: "Small Batches",
    titleText: "Small Batches",
    text: "",
    isDynamicText: false,
    type: "duration",
  },
  {
    id: 2,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Exclusive Content",
    altText: "Exclusive Content",
    text: "",
    isDynamicText: false,
    type: "tests",
  },
  {
    id: 3,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Exclusive",
    altText: "Evaluation",
    text: "Mock Tests",
    isDynamicText: false,
    type: "writing",
  },
  {
    id: 4,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Extra Questions",
    altText: "Speaking",
    text: "for Practice",
    isDynamicText: false,
    type: "speaking",
  },
];

export const ieltsFoundationValuePropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    altText: "Designed",
    titleText: "Designed For",
    text: "IELTS Beginners",
    isDynamicText: false,
  },
  {
    id: 2,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    altText: "Skill-Building",
    titleText: "Skill-Building",
    text: "Course",
    isDynamicText: false,
  },
  {
    id: 3,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Upskill self to learn IELTS",
    altText: "Speaking",
    text: "syllabus after 2 weeks",
    isDynamicText: false,
  },
  {
    id: 4,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Specially Curated",
    altText: "Evaluation",
    text: "Practice Tests",
    isDynamicText: false,
  },
];

export const ieltsPlusPropsData: Array<ValuePropType> = [
  {
    id: 1,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    altText: "Daily",
    titleText: "Daily",
    text: "Live Classes",
    isDynamicText: false,
  },
  {
    id: 2,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    altText: "Skill-Building",
    titleText: "Free Speaking",
    text: "Evaluation",
    isDynamicText: false,
  },
  {
    id: 3,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Study",
    altText: "Study",
    text: "materials",
    isDynamicText: false,
  },
  {
    id: 4,
    icon: `${imageBasePath}/avataran_assets/images/masterclass-type/tick-icon.svg`,
    titleText: "Mock",
    altText: "Mock",
    text: "Tests",
    isDynamicText: false,
  },
];
export type bannerValuePropType = {
  imgUrl: string;
  boldText?: string;
  defaultText: string;
};

export const plusBannerValuePropsData: bannerValuePropType[] = [
  {
    imgUrl: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/view_all/plus1.svg`,
    boldText: "LIVE",
    defaultText: "classes with experts",
  },
  {
    imgUrl: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/view_all/plus2.svg`,
    boldText: "Lifetime",
    defaultText: "access to recordings",
  },
  {
    imgUrl: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/view_all/plus3.svg`,
    boldText: "Mock tests",
    defaultText: "and study materials",
  },
];
export const premiumBannerValuePropsData: bannerValuePropType[] = [
  {
    imgUrl: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/view_all/premium1.svg`,
    defaultText: "Personalised Attention",
  },
  {
    imgUrl: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/view_all/premium2.svg`,
    defaultText: "Speaking & Writing evaluations",
  },
  {
    imgUrl: `${imageBasePath}/avataran_assets/images/ielts/value_props/ielts/view_all/premium3.svg`,
    defaultText: "5 Reading & Writing mock tests",
  },
];

export enum ietlsPlusFilterData {
  START_SOON = -1,
  ALL = -1,
  IELTS_PLUS_PART_2 = -2,
}

export const ieltsPlusFilterData = [
  { id: ietlsPlusFilterData.START_SOON, text: "Starts soon" },
  { id: 2, text: "All" },
  { id: 3, text: "Evening" },
  { id: 4, text: "Morning" },
  { id: 5, text: "Afternoon" },
];

export enum viewAllFilterType {
  ALL = "All_weeks",
  IELTS_PLUS_PART_2 = "IELTSPlusPart2",
}
