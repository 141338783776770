import { AnyAction } from 'redux';
import {
  FacultySchema,
  TeacherSchema,
} from 'Interfaces/reducerTypes/TeacherSchema';
import { HYDRATE } from 'next-redux-wrapper';
import { facultyDataParser } from 'utils/facultyUtils';

const initialState: FacultySchema = {
  facultyData: [],
  error: '',
};

const reducer = (state: FacultySchema = initialState, action: AnyAction) => {
  switch (action.type) {
    case HYDRATE:
      return {
        ...state,
        facultyData: action.payload.ieltsFaculty.facultyData,
      };
    case 'FETCH_FACULTIES_SUCCESS':
      const dataInreqFormat = facultyDataParser(action.payload);
      return {
        ...state,
        facultyData: dataInreqFormat,
      };
    case 'FETCH_FACULTIES_FAIL':
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
