import { call, put, takeLatest } from 'redux-saga/effects';
import { reviewActions } from 'components/Ielts/Reviews/redux/ReviewsActions';
import { reviewApis } from 'components/Ielts/Reviews/redux/ReviewsApis';
import { reviewParser } from 'utils/reviewUtils';

function* fetchReviews(): Generator<any, any, any> {
  try {
    const res = yield call(reviewApis.fetchReviews);
    const reviewsObj = reviewParser(res);
    yield put(reviewActions.fetchReviewsSuccess(reviewsObj));
  } catch (e: any) {
    yield put(
      reviewActions.updateError(
        'Something went wrong. Please try after sometime',
      ),
    );
    console.log(e);
  }
}

function* reviewsSaga() {
  yield takeLatest('FETCH_IELTS_REVIEWS', fetchReviews);
}

export default reviewsSaga;
