import { destroyCookie, parseCookies, setCookie } from 'nookies';
import * as next from 'next';

const MAX_AGE = 60 * 60 * 24 * 30; // 30 days

export type CookieReqContext =
  | Pick<next.NextPageContext, 'req'>
  | { req: next.NextApiRequest }
  | null
  | undefined;

export type CookieResContext =
  | Pick<next.NextPageContext, 'res'>
  | { res: next.NextApiResponse }
  | null
  | undefined;

export function saveCookie(
  ctx: CookieResContext,
  key: string,
  value: string,
  ttl?: number,
) {
  setCookie(ctx, key, value, {
    maxAge: ttl || MAX_AGE,
    expires: ttl
      ? new Date(Date.now() + ttl * 1000)
      : new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: process.env.NODE_ENV === 'production',
    path: '/',
    sameSite: 'lax',
  });
}

export function saveUtmCookie(
  ctx: CookieResContext,
  key: string,
  value: string,
  ttl?: number,
) {
  setCookie(ctx, key, value, {
    maxAge: ttl || MAX_AGE,
    expires: ttl
      ? new Date(Date.now() + ttl * 1000)
      : new Date(Date.now() + MAX_AGE * 1000),
    httpOnly: false,
    secure: true,
    path: '/',
    sameSite: 'none',
  });
}

export function deleteCookie(ctx: CookieResContext, key: string) {
  destroyCookie(ctx, key, {
    maxAge: -1,
    path: '/',
  });
}

export function getCookie(ctx: CookieReqContext, key: string): string {
  const cookies = parseCookies(ctx);
  return cookies[key];
}
export const cookiesConstant = {
  IELTS_COURSE_REFFERER: 'ieltsCourseReferer',
  IP_COUNTRY_KEY: 'IP_COUNTRY_KEY',
  USER_COUNTRY: 'USER_COUNTRY',
};

export const COOKIES_CONSTANT = {
  TOKEN_NAME: 'token',
  USER_ID: 'user_id',
  ANALYTICS_USER_ID: 'ajs_user_id',
  SESSION_TOKEN: 'sessiontoken',
  USER_COUNTRY: 'user_country',
  IP_COUNTRY_KEY: 'IP_COUNTRY_KEY',
};
