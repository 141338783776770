import React from 'react';
import App, { AppContext } from 'next/app';
import { initGA } from 'ga';
import { ParsedUrlQuery } from 'querystring';
import { getUtmDataClient, saveUtmData } from 'utils/utm_utils';
import { wrapper } from 'redux/store';
import { END } from 'redux-saga';
import { Store } from 'redux';
import { NextPageContext } from 'next';
import { saveCookie } from 'utils/cookie-utiils';
import Router from 'next/router';
import { AuthToken, WithLogin } from '@leapfinance/auth-kit';
import { navRoutes } from 'page_routes';
import '@leapfinance/frontend-commons/tailwind-compiled.css';
import 'public/static/scss/global/global.scss';

const CANONICAL_DOMAIN = 'https://leapscholar.com';

interface Props {
  query: ParsedUrlQuery;
  err: Error;
  pageProps: { auth: AuthToken };
}

declare module 'next' {
  export interface NextPageContext {
    /**
     * Provided by next-redux-wrapper: The redux store
     */
    store: Store;
  }
}

class ScholarApp extends App<Props> {
  canonicalURL = '';

  componentDidMount() {
    if (Object.keys(getUtmDataClient()).length > 0) {
      saveUtmData(null, getUtmDataClient());
    }
    initGA(this.props.pageProps?.auth?.decodedToken?.user_id?.user_id);
    const _pathSliceLength = Math.min.apply(Math, [
      Router.asPath.indexOf('?') > 0
        ? Router.asPath.indexOf('?')
        : Router.asPath.length,
      Router.asPath.indexOf('#') > 0
        ? Router.asPath.indexOf('#')
        : Router.asPath.length,
    ]);

    this.canonicalURL =
      CANONICAL_DOMAIN + Router.asPath.substring(0, _pathSliceLength);

    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = this.canonicalURL;
    document.head.appendChild(link);
  }

  static savePromocode(context: NextPageContext, query: any) {
    const promocode = query && query.promocode;
    if (!!promocode) saveCookie(context, 'promocode', promocode);
  }
  static async getInitialProps(appContext: AppContext) {
    ScholarApp.savePromocode(appContext.ctx, appContext.ctx.query);

    const appProps = await App.getInitialProps(appContext);
    if (appContext.ctx.req && appContext.Component.getInitialProps) {
      appContext.ctx.store.dispatch(END);
      await (appContext.ctx.store as any).sagaTask.toPromise();
    }
    return {
      query: appContext.ctx.query,
      ...appProps,
    };
  }

  render(): JSX.Element {
    const { Component, pageProps, err } = this.props;

    return (
      <>
        <Component {...pageProps} err={err} />
        <WithLogin
          appName={process.env.NEXT_PUBLIC_AUTH_APP_NAME || ''}
          apiPath={navRoutes.AUTH_PATH}
          logo="/avataran_assets/images/auth/login_logo.png"
          trueCallerDetails={{
            partnerKey: process.env.NEXT_PUBLIC_PARTNER_KEY as string,
            partnerName: process.env.NEXT_PUBLIC_PARTNER_NAME as string,
          }}
        />
      </>
    );
  }
}

export default wrapper.withRedux(ScholarApp);
